import React, { useEffect } from 'react';
import Section from '@components/Section';
import SectionTextarea from '@components/SectionTextarea';
import Questions from '@components/Questions';
import MetaDataTags from '@components/MetaDataTags'; // Import del componente MetaDataTags
import useSynthReportStore from '@stores/synthReportStore/useSynthReportStore'; // Import dello store SynthReport
import { useQuartersFilter } from '@stores/filters/useQuartersFilters'; // Import dello store Quarters
import { usePeersFilter, useTopicsFilter } from '@stores/filters';
import { Collapse, Divider } from 'antd';
import { Highlight } from '@models/synthReport';
import { useNavigate } from 'react-router-dom';
import { Report } from '@models/synthReport';
import useReportStore from '@stores/reportStore/useReportStore';
import { SingleNews } from '@models/news';
import MarkdownViewer from '@components/MarkdownViewer';
import { getLocaleDate } from '@utils/dateFormatter';

const AllSections: React.FC = (): JSX.Element => {
  const selectedPeer = usePeersFilter((state) => state.peer);
  const selectedTopic = useTopicsFilter((state) => state.topic);
  // Recupera il synthReport dallo store Zustand
  const { synthReport, news } = useSynthReportStore((state) => state);
  const { setReport, clearReport } = useReportStore((state) => state);

  // Recupera il quarter dallo store Zustand
  const { quarter } = useQuartersFilter((state) => state);
  const navigate = useNavigate();

  const noDataMessage =
    'No data available for the selected topic/peer combination.';

  useEffect(() => {
    clearReport();
  }, []);

  const reportViewer = (report: Report) => {
    setReport(report);
    navigate('/pdf-viewer');
  };

  const assistantPage = (): void => {
    navigate('/assistente');
  };

  function SectionHighlights(props: {
    index: number;
    highlight: Highlight;
  }): JSX.Element {
    const { index, highlight } = props;
    return (
      <div key={`highlight-${index}`} className="mb-4">
        {/* MetaDataTags per ciascun SectionTextarea */}

        <MetaDataTags
          quarter={quarter ? quarter.name : 'Unknown Quarter'} // Nome del quarter
          files={
            highlight.documents
              ? highlight.documents?.map((doc: { name: string }) => doc.name)
              : []
          } // Lista dei file dal synthReport
          date={highlight?.highlights_date}
        />
        <SectionTextarea
          content={highlight.highlights_markdown || highlight.highlights}
          modalContent={highlight.highlights_markdown || highlight.highlights}
          hasIcons={false}
        />
      </div>
    );
  }

  function DividerSectionHighlights(props: {
    highlights: Highlight[];
  }): JSX.Element {
    const { highlights } = props;
    return (
      <>
        {highlights &&
          highlights.map((highlight, indexHighlight) => {
            return (
              <>
                <Divider
                  key={`highlight-${indexHighlight}`}
                  orientation="left"
                  orientationMargin={20}
                  style={{ color: 'rgb(239 68 68)' }}
                >
                  {highlight.title}
                </Divider>
                <SectionHighlights
                  index={indexHighlight}
                  highlight={highlight}
                />
              </>
            );
          })}
      </>
    );
  }

  function SectionReport(params: {
    reports: Report[];
    title: string;
  }): JSX.Element {
    const { reports, title } = params;
    return (
      <Section title={title} results={reports.length} hasButton={true}>
        {reports.map((report, index) => (
          <div key={`report-${index}`} className="mb-4">
            {/* MetaDataTags per ciascun SectionTextarea */}
            <Divider
              orientation="left"
              orientationMargin={20}
              style={{ color: 'rgb(239 68 68)' }}
            >
              {report.title}
            </Divider>
            <MetaDataTags
              quarter={quarter ? quarter.name : 'Unknown Quarter'}
              files={report.documents?.map((doc) => doc.name)} // Lista dei file associati al report
              date={report?.extraction_date}
            />
            <SectionTextarea
              content={report.report}
              modalContent={report.report}
              hasIcons={true}
              viewerCallback={() => reportViewer(report)}
              assistantCallback={() => assistantPage()}
            />
          </div>
        ))}
      </Section>
    );
  }

  function SectionNews(params: { news: SingleNews[] }): JSX.Element {
    const { news } = params;
    return (
      <>
        {news?.map((singleNews, index) => (
          <div key={`news-${index}`} className="mb-4">
            {/* MetaDataTags per ciascun SectionTextarea */}
            <Divider
              orientation="left"
              orientationMargin={20}
              style={{ color: 'rgb(239 68 68)', fontSize: '20px' }}
            >
              {singleNews.title}

            </Divider>

            <div className="flex-auto border border-greyLdo rounded-lg mr-5 p-3 text-sm">
              <MarkdownViewer text={singleNews.content} />
              
              <div className="flex flex-row justify-end">
                <span><em>{getLocaleDate(singleNews.date)}</em></span>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }

  function NoDataSection(params: { title: string }): JSX.Element {
    const { title } = params;
    return (
      <Section title={title} results={0} hasButton={false}>
        <p className="italic font-light text-gray-500 mt-3">{noDataMessage}</p>
      </Section>
    );
  }

  return (
    <div className="w-full">
      {/* Sezione Highlights */}
      {synthReport.highlights?.length > 0 ? (
        <>
          <Section
            title="Highlights"
            results={synthReport.highlights.length}
            hasButton={true}
          >
            {
              <DividerSectionHighlights
                key={0}
                highlights={synthReport.highlights}
              />
            }
            {synthReport.highlights_grouped &&
              synthReport.highlights_grouped?.map((group, index) => {
                return (
                  <Collapse
                    className="mb-3"
                    items={[
                      {
                        key: `highlight-${index}`,
                        label: (
                          <span style={{ color: 'red', fontWeight: 'bold' }}>
                            {group.title}
                          </span>
                        ),
                        children: (
                          <DividerSectionHighlights
                            key={index}
                            highlights={group.highlights}
                          />
                        ),
                      },
                    ]}
                  ></Collapse>
                );
              })}
          </Section>
        </>
      ) : (
        <NoDataSection title="Highlights" />
      )}

      {/* Sezione Reports */}
      {selectedPeer &&
        selectedTopic &&
        (synthReport.reports && synthReport.reports?.length > 0 ? (
          <>
            <SectionReport
              reports={synthReport.reports}
              title="Reports"
            ></SectionReport>
          </>
        ) : (
          <NoDataSection title="Reports"></NoDataSection>
        ))}

      {/* Sezione Questions & Answers */}
      {selectedPeer &&
        selectedTopic &&
        (synthReport.transcripts?.length > 0 &&
        synthReport.transcripts[0].relevant_elements.length > 0 ? (
          <>
            <Questions
              title="Questions & Answers"
              results={synthReport.transcripts[0]?.relevant_elements.length}
              hasButton={false}
              questions={synthReport?.transcripts[0]}
              quarter={quarter ? quarter.name : 'Unknown Quarter'}
              files={synthReport.transcripts[0]?.documents?.map(
                (doc) => doc.name,
              )}
            />
          </>
        ) : (
          <NoDataSection title="Questions & Answers"></NoDataSection>
        ))}
      {/* Sezione News */}
      {(selectedPeer || selectedTopic) &&
        (news.length > 0 ? (
          <>
            <Section title="Top News" results={news.length} hasButton={true}>
              <SectionNews news={news}></SectionNews>
            </Section>
          </>
        ) : (
          <NoDataSection title="Top News"></NoDataSection>
        ))}
    </div>
  );
};

export default AllSections;
