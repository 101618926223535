import useChatMessage from '@services/chatMessage/useChatMessage';
import { BsPinAngleFill, BsPinAngle } from 'react-icons/bs';
import usePromptService from '@services/promptService/usePromptService';
import { useState } from 'react';

interface Tag {
  id: number;
  name: string;
}

interface PromptCardProps {
  id: string;
  title: string;
  description: string;
  tags: Tag[];
  pinned: boolean;
  onPinToggle?: (id: string, pinned: boolean) => void; // Passiamo una funzione callback per gestire il toggle
}

const PromptCard = ({
                      id,
                      title,
                      description,
                      tags,
                      pinned,
                      onPinToggle,
                    }: PromptCardProps): JSX.Element => {
  const setMsg = useChatMessage((state) => state.setChatMessage);
  const [isPinned, setIsPinned] = useState(pinned);
  const [isLoading, setIsLoading] = useState(false);
  const { setPin } = usePromptService();

  const handlePinClick = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.stopPropagation();
    setIsLoading(true);

    try {
      await setPin({ prompt: id, pin: !isPinned });
      setIsPinned(!isPinned);
      if (onPinToggle) {
        onPinToggle(id, !isPinned); // Chiama la funzione callback per aggiornare lo stato del prompt nel tab
      }
    } catch (error) {
      console.error('Error pinning/unpinning prompt:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      onClick={() => setMsg(description)}
      className="w-96 p-3 border-2 border-greyLdo mt-10 h-40 rounded-xl flex flex-col justify-between hover:border-red-500 cursor-pointer"
    >
      <div className="flex justify-between">
        <span className="font-bold text-2xl text-darkBlueLdo">{title}</span>
        <button
          onClick={handlePinClick}
          disabled={isLoading}
          className={`border-2 p-1 rounded-md hover:text-white ${
            isPinned
              ? 'bg-red-500 text-white border-red-500'
              : 'hover:bg-red-500 hover:border-red-500 border-greyLdo text-darkBlueLdo'
          }`}
        >
          {isPinned ? <BsPinAngleFill size={24} /> : <BsPinAngle size={24} />}
        </button>
      </div>
      <div className="mt-4">
        <p>{description}</p>
      </div>
      <div className="mt-auto flex flex-wrap">
        {tags.map((tag) => (
          <span
            key={tag.id}
            className="mr-2 border border-greyLdo rounded-full py-1 px-4 mt-2 text-sm"
          >
            {tag.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default PromptCard;
