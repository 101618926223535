import { Tabs, TabsProps } from "antd";
import '@pages/Signals/styles.css';
import SignalOtherContainer from "@components/SignalOtherContainer/SignalOtherContainer";
import SignalPeerContainer from "@components/SignalPeerContainer/SignalPeerContainer";

const Signals = (): JSX.Element => {
    const activateKey = (location.pathname !== '/signals/others')? '1' : '2';

    const items: TabsProps['items'] = [
        {
          key: '1',
          label: (
            <span className="font-bold text-lg text-darkBlueLdo p-1 mb-2">
              Signals from Peers
            </span>
          ),
          children: <SignalPeerContainer ></SignalPeerContainer>,
          className: 'h-full flex flex-col w-full',
        },
        {
          key: '2',
          label: (
            <span className="font-bold text-lg text-darkBlueLdo p-1 mb-2">
              Signals from A&D Industry
            </span>
          ),

          children: <SignalOtherContainer></SignalOtherContainer>,
          className: 'h-full flex flex-col w-full',
        },
      ];

    const changePathLocation = (key: string) => {
        if (key === '1') {
          window.history.replaceState(null, '', '/signals');
        } else {
          window.history.replaceState(null, '', '/signals/others');
        }
    }
  

  return (
    <>
      <div className="flex flex-col items-center justify-center text-darkBlueLdo my-8">
        <span className="text-4xl font-bold mb-6">Industry Signals</span>
        <Tabs
            centered
            onChange={(key) => {changePathLocation(key)}}
            className="my-tabs-infos h-full w-full flex"
            defaultActiveKey={activateKey}
            items={items}
          />
      </div>
    </>
  );
};

export default Signals;


