import PromptCard from '@components/PromptCard';
import React, { useEffect } from 'react';
import usePromptStore from '@stores/promptStore/promptStore';
import usePromptService from '@services/promptService/usePromptService';

interface PromptMenuProps {
  activeTab: string;
}

const PromptMenu: React.FC<PromptMenuProps> = ({ activeTab }) => {
  const { prompts, hasMore, setPrompts, resetPrompts, loadMorePrompts } = usePromptStore();
  const { getPrompts } = usePromptService();

  useEffect(() => {
    let pinnedStatus: 'true' | 'false' | 'none' = 'none';
    resetPrompts();

    if (activeTab === 'My Prompts') {
      pinnedStatus = 'true';
    } else if (activeTab === 'All the prompts') {
      pinnedStatus = 'none';
    } else if (activeTab === 'Latest') {
      pinnedStatus = 'none';
    }

    getPrompts(pinnedStatus).then((response) => {
      // Ordina i prompt per data di modifica, in ordine decrescente
      const sortedPrompts = response.sort((a, b) =>
        new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime()
      );

      if (activeTab === 'Latest') {
        setPrompts(sortedPrompts, true); // Carica solo i primi 3 prompt per "Latest"
      } else {
        setPrompts(sortedPrompts); // Carica tutti i prompt per "All the prompts" o "My Prompts"
      }
    }).catch((error) => {
      console.error('Error fetching prompts:', error);
    });
  }, [activeTab]);

  const handlePinToggle = (id: string, pinned: boolean) => {
    if (activeTab === 'My Prompts' && !pinned) {
      // Filtra il prompt rimosso dal pinned status
      const updatedPrompts = prompts.filter(prompt => prompt.id !== Number(id));
      setPrompts(updatedPrompts);
    }
  };

  return (
    <div className="w-full flex flex-col">
      <div className={`flex justify-evenly flex-wrap w-full`}>
        {prompts.map((prompt, index) => (
          <PromptCard
            id={String(prompt.id)}
            key={index}
            title={prompt.title}
            description={prompt.description}
            tags={prompt.tags}
            pinned={prompt.is_pinned}
            onPinToggle={handlePinToggle} // Passa la funzione handlePinToggle come callback
          />
        ))}
      </div>
      {activeTab === 'Latest' && hasMore && (
        <div className="flex justify-center items-center mt-5">
          <button
            onClick={loadMorePrompts}
            className="px-24 py-2 border-2 border-darkBlueLdo text-darkBlueLdo rounded-md hover:bg-darkBlueLdo hover:text-white">
            Load more
          </button>
        </div>
      )}
    </div>
  );
};

export default PromptMenu;
