import { ModelState } from '@stores/modelStore/useModelsStore';
import { create } from 'zustand';

export interface Chat {
  created_at: string;
  created_by: number;
  id: number;
  modified_at: string;
  modified_by: number;
  peer: string | null;
  title: string;
  topic: string | null;
  user: number;
  thread_id: string;
  llm_model?: ModelState;
}

interface ChatArchiveState {
  chats: Chat[];
  addChat: (chat: Chat) => void;
  updateChatTitle: (id: number, title: string) => void;
  deleteChat: (id: number) => void;
  setChats: (chats: Chat[]) => void;
  chatId: number | null;
  setChatId: (chatId: number | null) => void;
}

export const useChatArchive = create<ChatArchiveState>((set) => ({
  chats: [],
  addChat: (chat: Chat): void =>
    set((state) => ({
      chats: [...state.chats, chat],
    })),
  updateChatTitle: (id: number, title: string): void =>
    set((state) => ({
      chats: state.chats.map((chat) =>
        chat.id === id ? { ...chat, title } : chat,
      ),
    })),
  deleteChat: (id: number): void =>
    set((state) => ({
      chats: state.chats.filter((chat) => chat.id !== id),
    })),
  setChats: (chats: Chat[]): void => set({ chats }),

  chatId: null,
  setChatId: (chatId: number | null): void => set({ chatId }),
}));
