import React, { useEffect } from 'react';
import { useTopicsFilter } from 'stores/filters';
import useTopicService from '@services/topicService/useTopicService';
import { Topic } from '@stores/filters/useTopicsFilter';
import useTopicsStore from '@stores/topicsStore/useTopicsStore';
import SearchableDropdownSection from '@components/SearchableDropdownSection';

export interface FilterDropdownContainerProps {
  dropdownTopicDescription: string;
}

const FilterDropdownContainer = (
  props: FilterDropdownContainerProps,
): JSX.Element => {
  const {dropdownTopicDescription} = props;

  const [topics, setTopics] = useTopicsStore((state) => [
    state.topics,
    state.setTopics,
  ]);


  const { getAllTopics, searchTopics } = useTopicService();

  const topicFilter = useTopicsFilter((state) => state.topic);
  const setTopicFilter = useTopicsFilter((state) => state.setTopic);
  const clearTopicFilter = useTopicsFilter((state) => state.clearTopic);


  useEffect(() => {
    // Richiama la funzione getAllTopics quando il componente viene montato
    getAllTopics().then((response) => {
      // Salva i dati restituiti dalla chiamata API nello stato locale
      setTopics(response.data);
    });
  }, []);

  const handleSearchTopic = (toSearch: string): void => {
    searchTopics(toSearch).then(
      (response: { data: React.SetStateAction<Topic[]> }) => {
        setTopics(response.data as Topic[]);
      },
    );
  };

  const handleClickDropdownTopic = (e: number): void => {
    const topic: Topic = topics.find((topic) => topic.id === e)!;
    setTopicFilter(topic);
  };



  return (
        <SearchableDropdownSection
          elements={topics}
          selectedElement={topicFilter}
          handleSearch={handleSearchTopic}
          handleClickDropdown={handleClickDropdownTopic}
          handleCleanDropdown={clearTopicFilter}
          dropdownDescription={dropdownTopicDescription}
          isSearchble={true}
        />
  );
};

export default FilterDropdownContainer;
