import { Card, Col, Row } from "antd";

interface CardsScheletonProps {
    loading: boolean
}

const CardsScheleton: React.FC<CardsScheletonProps> = ({loading}): JSX.Element => {
    return (
        <Row gutter={16} className="m-0 p-6 w-full">
          <Col span={8}>
            <Card loading={loading} style={{ minWidth: 300 }}></Card>
          </Col>
          <Col span={8}>
            <Card loading={loading} style={{ minWidth: 300 }}></Card>
          </Col>
          <Col span={8}>
            <Card loading={loading} style={{ minWidth: 300 }}></Card>
          </Col>
        </Row>
      );
};

export default CardsScheleton;

