import useAxios from '@hooks/api/useAxios';
import { AxiosResponse } from 'axios';

interface PeerNewsParameters {
  topic_id?: number,
  company_id?: number,
  cluster_id?: number,
  sort_by_ranking?: boolean,
  sort_by_date?: boolean,
  ranking_above?: number,
  limit?: number,
}

interface OtherNewsParameters {
  cluster_id?: number,
  sort_by_ranking?: boolean,
  sort_by_date?: boolean,
  ranking_above?: number,
  limit?: number, 
}

type NewsService = {
  getPeerNews: (
    params: Partial<PeerNewsParameters>
  ) => Promise<AxiosResponse>;
  getOtherNews: (
    params: Partial<OtherNewsParameters>
  ) => Promise<AxiosResponse>;
  getPeerSignals: (signalId?: number) => Promise<AxiosResponse>;
  getOtherSignals: (signalId?: number) => Promise<AxiosResponse>;
};

const useNewsService = (): NewsService => {
  const api = useAxios();

  const getPeerNews = (
    params: Partial<PeerNewsParameters>
  ): Promise<AxiosResponse> =>
    api.get(`/api/news/get-peers-news`, {
      params: {
        ...params
      },
    });

  const getOtherNews = (
    params: Partial<OtherNewsParameters>
  ): Promise<AxiosResponse> =>
    api.get(`/api/news/get-other-news`, {
      params: {
        ...params
      },
    });

  const getPeerSignals = (signalId?: number): Promise<AxiosResponse> =>
    api.get(`/api/news/get-peers-clusters`, {
      params: { cluster_id: signalId },
    });

  const getOtherSignals = (signalId?: number): Promise<AxiosResponse> =>
    api.get(`/api/news/get-other-clusters`, {
      params: { cluster_id: signalId },
    }); 
    

  /*
  const getPeerSignals = (signalId?: number): Promise<AxiosResponse> =>
    api.get('/mock-apis/json/Signals.json', {
      baseURL: 'http://localhost:3000',
    });
  const getPeerNews = (
    topic_id?: number,
    company_id?: number,
    cluster_id?: number,
    sort_by_ranking?: boolean,
    ranking_above?: number,
    limit?: number,
  ): Promise<AxiosResponse> =>
    api.get('/mock-apis/json/News.json', { baseURL: 'http://localhost:3000' });

  const getOtherNews = (
    cluster_id?: number,
    sort_by_ranking?: boolean,
    ranking_above?: number,
    limit?: number,
  ): Promise<AxiosResponse> =>
    api.get('/mock-apis/json/News.json', { baseURL: 'http://localhost:3000' });



  const getOtherSignals = (signalId?: number): Promise<AxiosResponse> =>
    api.get('/mock-apis/json/Signals.json', {
      baseURL: 'http://localhost:3000',
    });
    */

  return {
    getPeerNews,
    getOtherNews,
    getPeerSignals,
    getOtherSignals,
  };
};

export default useNewsService;
