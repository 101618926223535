import { ReactNode } from 'react';
import { IoIosSearch, IoMdClose } from 'react-icons/io';
import { LiaComment } from 'react-icons/lia';
import { IoCopyOutline } from 'react-icons/io5';

// in the future also give  handleCopy, handleSearch, handleComment as props

interface ModalProps {
  onClose: () => void;
  title?: string;
  children: ReactNode;
}

const Modal = ({ onClose, title, children }: ModalProps): JSX.Element => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-3xl w-full z-50">
        <div className="flex justify-between items-center pt-4 px-6">
          {title && <h2 className="text-2xl font-bold">{title}</h2>}
          <button
            className="rounded-full bg-red-500 p-2 text-white"
            onClick={onClose}
          >
            <IoMdClose size={24} className="hover:scale-110" />
          </button>
        </div>
        <div className="p-4">{children}</div>
        <div className="pb-4 px-6 flex justify-end">
          <button className="flex items-center justify-center border-2 border-gray-300 rounded-lg p-2 hover:scale-105 mr-2">
            <IoCopyOutline size={20} />
          </button>
          <button className="flex items-center justify-center border-2 border-gray-300 rounded-lg p-2 hover:scale-105 mr-2">
            <IoIosSearch size={20} />
          </button>
          <button className="flex items-center justify-center border-2 border-gray-300 rounded-lg p-2 hover:scale-105 mr-2">
            <LiaComment size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
