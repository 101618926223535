import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxios from '@hooks/api/useAxios.ts';
import useAuthStore from '@stores/authStore/useAuthStore';
import Logo from '@assets/Logo.svg';
import { useToastStore } from '@services/toast';

const Login = (): JSX.Element => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const setToken = useAuthStore((state) => state.setToken);
  const showToast = useToastStore((state) => state.showToast);
  const navigate = useNavigate();
  const fetch = useAxios();

  const handleLogin = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();
    try {
      const response = await fetch.post('/api/token/', { username, password });
      setToken(response.data.access);
      navigate('/');
    } catch (error) {
      setUsername('');
      setPassword('');
      showToast('Errore: username o password errati');
    }
  };

  return (
    <div className="w-full flex min-h-screen text-darkBlueLdo">
      <div className="w-2/5 bg-[#E3E4E9] flex justify-center items-center">
        <img src={Logo} alt="Logo Leonardo" className="w-11/12" />
      </div>
      <div className="flex flex-col w-3/5 mt-40">
        <div className="mb-36 flex flex-col items-center">
          <h1 className="font-bold text-6xl m-4">Welcome back!</h1>
          <p className="text-xl">Enter your data to access</p>
        </div>
        <form
          onSubmit={handleLogin}
          className="flex flex-col items-center justify-center mb-16"
        >
          <div className="w-full flex justify-center mb-6">
            <div className="w-1/3">
              <label htmlFor="username" className="block mb-2 w-full font-bold">
                Username:
              </label>
              <input
                id="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                name="username"
                required
                className="w-full border border-gray-300 p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>
          <div className="w-full flex justify-center mb-4">
            <div className="w-1/3">
              <label htmlFor="password" className="block mb-2 w-full font-bold">
                Password:
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                required
                className="w-full border border-gray-300 p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>
          <div className="w-1/3 flex items-center mb-16">
            <input
              id="rememberMe"
              type="checkbox"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              className="mr-2 hover:cursor-pointer"
            />
            <label htmlFor="rememberMe">Remember me</label>
          </div>
          <button
            type="submit"
            className="btn-redLdo w-1/3"
            disabled={!username || !password}
          >
            Accedi
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
