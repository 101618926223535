import { useState, ChangeEvent, FormEvent } from 'react';
import useChatHistory from '@stores/chatHistoryStore/chatHistory';
import useChangeLayout from '@services/assistenteLayout';
import { useToastStore } from '@services/toast';
import useChatMessage from '@services/chatMessage/useChatMessage';
import { useChatArchive } from '@services/chatArchive/useChatArchive';
import useChatService from '@services/chatService/useChatService';
import { useChatArchiveFiltered } from '@services/chatArchiveFiltered/useChatArchiveFiltered';
import useModelStore from '@stores/modelStore/useModelsStore';

interface JsonObject {
  type: string;
  content: string;
}

type UseSendMessagesReturn = {
  handleSubmit: (
    e: FormEvent<HTMLButtonElement | HTMLFormElement>,
    threadId: string,
  ) => Promise<void>;
  onMsgChange: (e: ChangeEvent<HTMLInputElement>) => void;
  msg: string;
};

const useSendMessages = (): UseSendMessagesReturn => {
  const [promptDisable, setPromptDisable] = useState<boolean>(false);
  const msg = useChatMessage((state) => state.chatMessage);
  const setMsg = useChatMessage((state) => state.setChatMessage);
  const clearMsg = useChatMessage((state) => state.clearChatMessage);
  const chatHistory = useChatHistory((state) => state.chatHistory);
  const setChatHistory = useChatHistory((state) => state.setChatHistory);
  const addStreamChatMessages = useChatHistory(
    (state) => state.addStreamChatMessages,
  );
  const updateLastChatMessageText = useChatHistory(
    (state) => state.updateLastChatMessageText,
  );
  const setLayout = useChangeLayout((state) => state.setLayout);
  const showToast = useToastStore((state) => state.showToast);
  const { addChat, chatId, setChatId } = useChatArchive();
  const { createChat, createChatMessage, createRemoteChainV3 } =
    useChatService();
  const { addFilteredChat } = useChatArchiveFiltered();
  const model = useModelStore((state)=> state.model);
  // Function to send both user and AI messages
  const sendBothChatMessages = async (
    id: number,
    threadId: string,
  ): Promise<void> => {
    try {
      // First, send the human message
      await createChatMessage(id, { role: 'human', message: msg , llm_model: model });

      // Prepare the payload for the POST request
      const prompt = {
        message_content: msg,
        thread_id: threadId,
        model_name: model,
      };

      // Add the user's message to the chat history
      setChatHistory({ role: 'human', message: msg, llm_model: model });
      clearMsg();

      // Add a placeholder for the AI message
      setChatHistory({ role: 'ai', message: '...', llm_model: model });
      let tempMessage: string = '';
      try {

        const response = await createRemoteChainV3(prompt);
        if (response.status !== 200) {
          console.error('Error during the request:', response.statusText);
          return;
        }
        //Manage the binary flow of the response
        if (response.body) {
          const reader = response.body.getReader();
          if (reader) {
            const decoder = new TextDecoder();
            let result = await reader.read();
            while (!result.done) {
              const { value } = result;
              try {
                // Decode chunks and call the callback with progress
                const chunkText = decoder.decode(value, { stream: true });
                const jsonArrayString = chunkText.trim().replace(/,$/, '');
                const jsonArray = JSON.parse(`[${jsonArrayString}]`);
                addStreamChatMessages(jsonArray);
                jsonArray.forEach((jsonObj: JsonObject) => {
                  if (jsonObj.type === 'chunk') {
                    updateLastChatMessageText(jsonObj.content);
                    tempMessage += jsonObj.content;
                  }
                });
              } catch (error) {
                console.error('Error parsing JSON:', error);
              }
              result = await reader.read();
            }
          }
        }
      } catch (error) {
        console.error('Error during the request:', error);
        return
      }

      // Make the POST request to get the AI response
      // const response = await createRemoteChain(prompt);
      // Response received as a string

      // Remove the placeholder and update with the actual response
      // setChatHistory({ role: 'ai', message: aiMessage });

      // Save the AI message in the system
      await createChatMessage(id, {
        role: 'ai',
        message: tempMessage,
        llm_model: model,
      });
    } catch (error) {
      showToast('Error during the server request');
      setPromptDisable(false);
    } finally {
      setPromptDisable(false);
    }
  };

  const sendMessages = async (threadId: string): Promise<void> => {
    if (chatHistory.length === 0) {
      try {
        // Create a new chat if it doesn't exist
        const response = await createChat({ title: msg, thread_id: threadId, llm_model: model });
        addChat(response.data);
        addFilteredChat(response.data);

        const id = response.data.id;
        setChatId(id);

        // Send the messages
        await sendBothChatMessages(id, threadId);
      } catch (error) {
        showToast('Error: failed to create chat');
        console.log('Error creating chat', error);
        setPromptDisable(false);
      }
    } else {
      if (chatId !== null) {
        await sendBothChatMessages(chatId, threadId);
      } else {
        showToast('Error: chatId is null');
        setPromptDisable(false);
        return;
      }
    }
  };

  const handleSubmit = async (
    e: FormEvent<HTMLButtonElement | HTMLFormElement>,
    threadId: string,
  ): Promise<void> => {
    e.preventDefault();

    if (msg.trim() === '' || promptDisable) {
      return;
    }
    setLayout('chat');
    setPromptDisable(true);
    await sendMessages(threadId);
  };

  const onMsgChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setMsg(e.target.value);
  };

  return { handleSubmit, onMsgChange, msg };
};

export default useSendMessages;
