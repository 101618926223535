// import { LuPencil } from 'react-icons/lu';
import Markdown from 'react-markdown';
import MetaLogo from '@assets/meta.png';
import ChatGPTLogo from '@assets/ChatGPT-Logo.png';
import { Avatar } from 'antd';
import { ModelState } from '@stores/modelStore/useModelsStore';

const PersonMessage = ({ message, model }: { message: string, model?: ModelState }): JSX.Element => {
  let img = MetaLogo
  if(model){
    switch (model) {
      case 'llm_llama_31_mod':
        img = MetaLogo
        break
      case 'llm_gpt_4o_mod':
        img = ChatGPTLogo
        break 
    }
  }

  return (
    <div className="flex justify-end items-center my-2">
      {/* <button className="mr-5 rounded border-2 border-greyLdo p-1 h-9 hover:border-black">
        <LuPencil className="h-full w-full" />
      </button> */}
      <span className="rounded bg-greyLdo w-auto px-2 py-3 flex justify-between items-center">
        {model && <Avatar className='mr-4' style={{ backgroundColor: '#fff' }} src={img} />}
        <div className="flex">
          <Markdown>{message}</Markdown>
        </div>
      </span>
    </div>
  );
};

export default PersonMessage;
