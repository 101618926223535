import PreviousChat from '@components/PreviousChat';
import { getDateCategory } from '@utils/dateFormatter';
import { Chat } from '@services/chatArchive/useChatArchive';
import DateSeparator from '@components/DateSeparator/DateSeparator.tsx';

const ChatArchive = ({
  filteredChats,
}: {
  filteredChats: Chat[];
}): JSX.Element => {
  let lastDateCategory = '';

  return (
    <div className="overflow-y-auto flex-grow overflow-x-hidden">
      {[...filteredChats]
        .sort((a, b) => b.modified_at.localeCompare(a.modified_at))
        .map((chat) => {
          const dateCategory = getDateCategory(chat.modified_at);
          const showDateCategory = dateCategory !== lastDateCategory;
          lastDateCategory = dateCategory;

          return (
            <div key={chat.id}>
              {showDateCategory && <DateSeparator dateCategory={dateCategory} />}
              <PreviousChat
                id={chat.id}
                title={chat.title}
                chatThreadId={chat.thread_id}
                chatModel={chat?.llm_model}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ChatArchive;
