import { Peer } from '@models/peer';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface PeerState {
  peer: Peer | null;
  setPeer: (peerToChange: Peer | null) => void;
  clearPeer: () => void;
}

export const usePeersFilter = create<PeerState>()(
  persist(
    (set) => ({
      peer: null,
      setPeer: (peerToChange: Peer | null): void => set({ peer: peerToChange }),
      clearPeer: (): void => set({ peer: null }),
    }),
    {
      name: 'peer',
      storage: {
        getItem: (name) => {
          const item = localStorage.getItem(name);
          return item ? JSON.parse(item) : null;
        },
        setItem: (name, value) => {
          localStorage.setItem(name, JSON.stringify(value));
        },
        removeItem: (name) => {
          localStorage.removeItem(name);
        },
      },
    }
  )
);