import useAxios from '@hooks/api/useAxios';
import { AxiosResponse } from 'axios';
import {
  Prompt,
  SetPinResponse,
} from './apiTypes';

interface PinData {
  prompt: string;
  pin: boolean;
}

interface UsePromptService {
  setPin: (data: PinData) => Promise<SetPinResponse>;
  getPrompts: (pinned?: string) => Promise<Prompt[]>;
}

const usePromptService = (): UsePromptService => {
  const api = useAxios();


  const setPin = async (data: PinData): Promise<SetPinResponse> => {
    const response: AxiosResponse<SetPinResponse> = await api.post('/api/prompts/set-pin', data);
    return response.data;
  };

  const getPrompts = async (pinned?: string): Promise<Prompt[]> => {
    const params = pinned !== undefined ? { pinned } : {};
    const response: AxiosResponse<Prompt[]> = await api.get('/api/prompts', { params });
    return response.data;
  };

  return {
    setPin,
    getPrompts,
  };
};

export default usePromptService;
