import SidebarPeerSearchFilter from '@components/SidebarPeerSearchFilter';
import { usePeersFilter, useTopicsFilter } from 'stores/filters';
import { useSynthReportService } from '@services/report';
import AnalysisStatus from '@components/AnalysisStatus';
import AllSections from '@components/AllSections';
import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useQuartersFilter } from '@stores/filters/useQuartersFilters';
import useSynthReportStore from '@stores/synthReportStore/useSynthReportStore';
import FitlersPillContainer from '@components/FitlersPillContainer';
import NewsComponent from '@components/NewsComponent/NewsComponent.tsx';
import useNewsService from '@services/newsService/useNewsService';
import './styles.css';
import { useToastStore } from '@services/toast';
import { Button } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {formatText} from '@utils/newsFormatter';
import { SingleNews } from '@models/news';

const Report = (): JSX.Element => {
  const selectedPeer = usePeersFilter((state) => state.peer);
  const selectedDropdown = useTopicsFilter((state) => state.topic);
  const selectedQuarter = useQuartersFilter((state) => state.quarter);

  // const setTranscriptFilter = useTranscriptFilter(
  //   (state) => state.setQuestions,
  // );

  const { getSynthReport } = useSynthReportService();
  const { getPeerNews } = useNewsService();
  const { setSynthReport, setNews } = useSynthReportStore();
  const showToast = useToastStore((state) => state.showToast);
  const [warningVisible, setIsWarningVisible] = useState(true);

  useEffect(() => {
    if (selectedDropdown || selectedPeer || selectedQuarter) {
      (selectedQuarter?.value === 3 || selectedQuarter?.value === 4) && setIsWarningVisible(true);
      getSynthReport(
        selectedPeer ? selectedPeer?.id : null,
        selectedDropdown ? selectedDropdown?.id : null,
        selectedQuarter ? selectedQuarter?.value : null,
      )
        .then((synthReport: AxiosResponse) => {
          setSynthReport(synthReport.data);
        })
        .catch(() => {
          setSynthReport({
            highlights: [],
            highlights_grouped: [],
            reports: [],
            transcripts: [],
          });
          showToast('Error: Error on the retrieval of the synth report');
        });
    }
    if (selectedDropdown || selectedPeer) {
      getPeerNews({
        topic_id: selectedDropdown?.id,
        company_id: selectedPeer?.id,
        sort_by_date: true,
        limit: 5,
      })
        .then((news: AxiosResponse) => {
          let newsFormatted = news.data.map((news: SingleNews) => {
            return {...news, content: formatText(news.content)}
          } ); 
          setNews(newsFormatted);
        })
        .catch(() => {
          setNews([]);
          showToast('Error: Error on the retrieval of the news');
        });
    } else {
      setNews([]);
    }

    /*     let path: string = '';
    if (selectedDropdown && selectedPeer) {
      path = `/mock-apis/json/synth_report_payload.json`;
    } else if (selectedDropdown) {
      path = `/mock-apis/json/synth_report_payload_topic.json`;
    } else if (selectedPeer) {
      path = `/mock-apis/json/synth_report_payload_company.json`;
    }
    getSynthReport(path).then((synthReport: AxiosResponse) => {
      setSynthReport(synthReport.data);
    }); */

    //setTranscriptFilter('' + selectedPeer, selectedDropdown);
  }, [selectedPeer, selectedDropdown, selectedQuarter]);

  const WarningMessage = () => {
    return (
      <div
        className={`${!warningVisible? 'close-animation': null} my-10 max-w-6xl mx-auto p-4 border-l-4 border-blue-500 bg-blue-50 rounded-md shadow-md`} 
      >
        <div className="flex items-center">
          <ExclamationCircleFilled  className="text-blue-500 text-xl mr-3" />
          <div className="flex-1">
            <span className="text-gray-800 font-medium">
              <b>Notes: </b>
                 The Q{selectedQuarter?.value} reports are based on preliminary data and may be subject
                to adjustments. Please consider this when interpreting the
                results.
              
            </span>
          </div>
          <Button
            type="text"
            className="ml-4"
            onClick={() => setIsWarningVisible(false)}
          >
            ✕
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col items-left bg-[#39414C] text-darkBlueLdo p-4 border-solid border-b border-b-black border-slate-300">
        <FitlersPillContainer />
        <div className="mt-5">{/* <TopicsQuarterFilter /> */}</div>
      </div>
      <div>
        <div className="flex">
          <SidebarPeerSearchFilter />
          <div className="flex-1 p-4 flex flex-col items-start">
            <AnalysisStatus />
            {selectedQuarter && selectedQuarter?.value > 2 && <WarningMessage />}
            {(selectedPeer || selectedDropdown) && (
              <AllSections />
            )}
            {!selectedPeer && !selectedDropdown && selectedQuarter && (
              <div className=" w-full text-center mt-20">
                <NewsComponent />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
