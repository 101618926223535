import React from 'react';

interface DateSeparatorProps {
  dateCategory: string;
}

const DateSeparator: React.FC<DateSeparatorProps> = ({ dateCategory }) => (
  <div className="mt-4 mb-2">
      <span className="font-semibold px-4 py-2 block">
        {dateCategory}
      </span>
  </div>
);

export default DateSeparator;
