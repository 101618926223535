export function formatText(input: string): string {
  return input
    .replace(/(Fact:)/g, '\n**$1**\n\n')
    .replace(/(Analysis:)/g, '\n\n\n**$1**\n\n')
    .replace(/(\n--)/g, '');
}

export function noH2Text(input: string): string {
  return input.replace(/(\n--)/g, '');
}


