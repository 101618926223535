interface SectionProps {
  title: string;
  hasButton: boolean;
  results: number;
  headline?: string;
  children: React.ReactNode;
}

const Section = ({
  title,
  // hasButton,
  results,
  headline,
  children,
}: SectionProps): JSX.Element => {
  return (
    <div className="mt-10">
      <div className="flex items-center">
        <span className="mr-1 whitespace-nowrap text-red-500 font-bold text-xl">
          {title}
        </span>
        <div className="bg-greyLdo h-[1px] flex-grow"></div>
      </div>
      {results > 0 && (
        <div className="text-sm text-slate-600 my-3">({results} risultati)</div>
      )}
      {headline && <span className="font-semibold text-lg">{headline}:</span>}
      {children}
{/*      {hasButton && (
        <div className="flex justify-center items-center">
          <button className="mt-10 border-2 border-darkBlueLdo rounded-lg p-2 font-semibold px-10 hover:bg-darkBlueLdo hover:text-white text-darkBlueLdo">
            Visualizza altri highlights
          </button>
        </div>
      )}*/}
    </div>
  );
};

export default Section;
