import TopicDropDown from '@components/SearchableDropdownSection/TopicDropDown.tsx';

interface ChatTopBarProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const ChatTopBar = ({
  activeTab,
  setActiveTab,
}: ChatTopBarProps): JSX.Element => {
  const tabs = [
    { label: 'All the prompts' },
    { label: 'Latest' },
    { label: 'My Prompts' },
  ];

  return (
    <div className="flex flex-col items-center">
      <div className="w-1/3 text-center flex flex-col gap-3">
        <h1 className="font-bold text-4xl">Find in library</h1>
        <TopicDropDown dropdownTopicDescription={ 'Select or search a subject' }
        />
      </div>

      <div className="mt-5 flex">
        {tabs.map((tab) => (
          <div
            key={tab.label}
            className={`mr-5 cursor-pointer ${
              activeTab === tab.label
                ? 'text-red-600 font-bold underline underline-offset-8'
                : ''
            }`}
            onClick={() => setActiveTab(tab.label)}
          >
            {tab.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatTopBar;
