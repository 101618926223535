import { usePopupInfo } from '@services/popupInfo/usePopupInfo';

const PopupInfo = (): JSX.Element => {
  const { text, isVisible } = usePopupInfo();

  if (!isVisible) return <></>;

  return (
    <div
      style={{
        zIndex: 100,
        position: 'fixed',
        top: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'grey',
        color: 'white',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        width: '16rem',
        textAlign: 'center',
        fontStyle: 'bold',
      }}
    >
      {text}
    </div>
  );
};

export default PopupInfo;
