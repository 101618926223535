import { useRef, useEffect } from 'react';
import useChatHistory from '@stores/chatHistoryStore/chatHistory';
import PersonMessage from '@components/PersonMessage';
import SystemMessage from '@components/SystemMessage';

const KnowledgebaseChat = (): JSX.Element => {
  const chatHistory = useChatHistory((state) => state.chatHistory);
  const messageContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  return (
    <div className="w-full h-full flex flex-col overflow-y-auto">
      <div
        ref={messageContainerRef}
        className="messageContainer flex-grow p-4">
        {chatHistory?.map((message, index) => (
          <div key={index}>
            {message.role === 'human' ? (
              <PersonMessage message={message.message} model={message.llm_model} />
            ) : (
              <SystemMessage message={message.message} model={message.llm_model} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default KnowledgebaseChat;
