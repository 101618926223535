import { SingleNews } from '@models/news';
import { getLocaleDate } from '@utils/dateFormatter';
import { Card, Modal, Tooltip } from 'antd';
import { useState } from 'react';
import { GrFormNextLink } from 'react-icons/gr';
import './styles.css';
import { truncateString } from '@utils/stringUtils';
import MarkdownViewer from '@components/MarkdownViewer';

interface NewsSingleCardsProps {
  singleNews: SingleNews;
  index: number;
}

const NewsSingleCard: React.FC<NewsSingleCardsProps> = ({
  singleNews,
  index,
}): JSX.Element => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal
        title={
          <h6 className="text-darkBlueLdo text-3xl font-bold mb-6">
            {singleNews.title}
          </h6>
        }
        className="news-container"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        maskClosable={true}
        footer={null}
        width={1000}
      >
        <MarkdownViewer text={singleNews.content} />
      </Modal>

      <Card
        key={index + '-news'}
        bordered={false}
        className="min-h-[200px] !shadow-md !shadow-gray-400"
      >
        <div className="flex flex-col justify-between min-h-[200px]">
          <div className="flex flex-row justify-start">
            <span>{getLocaleDate(singleNews.date)}</span>
            {/*<NavLink
                          to={`/signals/${singleNews.id}`}
                          className="flex items-center justify-center align-center font-bold"
                        >
                          Discover More <GrFormNextLink />
                        </NavLink>*/}
          </div>
          {/*<a
                          className="text-darkBlueLdo text-xs uppercase"
                          href={singleNews.source_url}
                        >
                          {singleNews.source}
                        </a> */}
          <Tooltip title={singleNews.title}>
            <h6 className="text-darkBlueLdo text-3xl font-bold">
              {truncateString(singleNews.title, 60)}
            </h6>
          </Tooltip>

          <div className="flex flex-row justify-start">
            {/*        <span>{singleNews.date}</span><NavLink
                          to={`/signals/${singleNews.id}`}
                          className="flex items-center justify-center align-center font-bold"
                        >
                          Discover More <GrFormNextLink />
                        </NavLink>*/}
            <div
              className="flex items-center justify-center align-center font-bold cursor-pointer"
              onClick={() => setOpen(true)}
            >
              Discover More <GrFormNextLink />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default NewsSingleCard;
