import React, { useEffect, useState } from 'react';
import PromptCard from '@components/PromptCard';
import usePromptService from '@services/promptService/usePromptService';
import { Prompt } from '@services/promptService/apiTypes';

const InitialChatLayout: React.FC = (): JSX.Element => {
  const { getPrompts } = usePromptService();
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPrompts = async (): Promise<void> => {
      try {
        const response = await getPrompts('none');

        // Ordina i prompt per data di modifica, in ordine decrescente
        const sortedPrompts = response.sort((a, b) =>
          new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime()
        );

        setPrompts(sortedPrompts.slice(0, 3));
      } catch (err) {
        console.error('Error fetching prompts:', err);
        setError('Failed to load prompts. Please try again later.');
      }
    };

    fetchPrompts();
  }, []);


  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-center justify-center text-darkBlueLdo">
        <span className="text-4xl font-bold">How can I help you?</span>
        <span className="mt-4 text-lg">
        Seek inspiration to start your search with the latest topics.
        </span>
      </div>
      <div className="flex justify-evenly flex-wrap">
        {prompts && prompts.map((prompt, index) => (
          <PromptCard
            key={index}
            id={String(prompt.id)}
            title={prompt.title}
            description={prompt.description}
            tags={prompt.tags}
            pinned={prompt.is_pinned}
          />
        ))}
      </div>
    </div>
  );
};

export default InitialChatLayout;
