import useAxios from '@hooks/api/useAxios';
import { AxiosResponse } from 'axios';

type TopicService = {
  getAllTopics: () => Promise<AxiosResponse>;
  initTopic: () => Promise<AxiosResponse>;
  getTopicById: (id: number) => Promise<AxiosResponse>;
  deleteTopicById: (id: number) => Promise<AxiosResponse>;
  searchTopics: (searchTerm: string) => Promise<AxiosResponse>;
};

const useTopicService = (): TopicService => {
  const api = useAxios();

  const getAllTopics = (): Promise<AxiosResponse> => api.get('/api/topics/');
  const initTopic = (): Promise<AxiosResponse> => api.post('/api/topics/init');
  const getTopicById = (id: number): Promise<AxiosResponse> => api.get(`/api/topics/${id}`);
  const deleteTopicById = (id: number): Promise<AxiosResponse> => api.delete(`/api/topics/${id}`);
  const searchTopics = (searchTerm: string): Promise<AxiosResponse> => api.get(`/api/topics/search?search_term=${searchTerm}`);

  return {
    getAllTopics,
    initTopic,
    getTopicById,
    deleteTopicById,
    searchTopics,
  };
};

export default useTopicService;