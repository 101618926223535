import React from 'react';
import { Worker, Viewer, SpecialZoomLevel, LoadError } from '@react-pdf-viewer/core';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { RenderSwitchSelectionModeProps, SelectionMode, selectionModePlugin } from '@react-pdf-viewer/selection-mode';
import { RenderZoomInProps, RenderZoomOutProps, RenderCurrentScaleProps, zoomPlugin } from '@react-pdf-viewer/zoom';
import { getFilePlugin, RenderDownloadProps } from '@react-pdf-viewer/get-file';
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from 'react-icons/ai';
import { PiCursorFill } from 'react-icons/pi';
// import { CgSearchLoading } from 'react-icons/cg';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/selection-mode/lib/styles/index.css';

interface PDFViewerProps {
  fileUrl: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ fileUrl }) => {
  const toolbarPluginInstance = toolbarPlugin();
  const getFilePluginInstance = getFilePlugin();
  const selectionModePluginInstance = selectionModePlugin();
  const zoomPluginInstance = zoomPlugin();

  const { SwitchSelectionModeButton } = selectionModePluginInstance;
  const { SwitchSelectionMode } = selectionModePluginInstance;
  const { Toolbar } = toolbarPluginInstance;
  const zoomLevels = [1, 1.25, 1.5, 2, 3, 4];

  const renderError = (error: LoadError): React.ReactElement  => {
    let message = '';
    switch (error.name) {
      case 'InvalidPDFException':
        message = 'The document is invalid or corrupted';
        break;
      case 'MissingPDFException':
        message = 'The document is missing';
        break;
      case 'UnexpectedResponseException':
        message = 'Unexpected server response';
        break;
      default:
        message = 'Cannot load the document';
        break;
    }

    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#e53e3e',
            borderRadius: '0.25rem',
            color: '#fff',
            padding: '0.5rem',
          }}
        >
          {message}
        </div>
      </div>
    );
  };

  return (
    <div className="pdf-viewer-container">
      {/* FIXME: è corretta questa dipendenza? se la tolgono dal server che succede? */}
      <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
        <div className="flex items-center bg-gray-200 border-b border-gray-300 p-2">
          <Toolbar>
            {(props: ToolbarSlot): JSX.Element => {
              const {
                CurrentPageInput,
                CurrentScale,
                Download,
                NumberOfPages,
                ZoomIn,
                ZoomOut,
              } = props;
              return (
                <>
                  {/*<div className="flex items-center px-2 border-r border-gray-300">*/}
                  {/*  <CgSearchLoading size={20} />*/}
                  {/*</div>*/}
                  <div className="flex items-center px-2 border-r border-gray-300">
                    <SwitchSelectionModeButton mode={SelectionMode.Hand} />
                    <SwitchSelectionMode mode={SelectionMode.Text}>
                      {(props: RenderSwitchSelectionModeProps) => (
                        <button
                          className="border-none rounded cursor-pointer p-2"
                          onClick={props.onClick}
                        >
                          <PiCursorFill size={20} />
                        </button>
                      )}
                    </SwitchSelectionMode>
                  </div>
                  <div className="flex items-center px-2 ">
                    <span>Pagina</span>
                    <CurrentPageInput />
                  </div>
                  <div className="flex items-center px-2 border-r border-gray-300">
                    <span>di <NumberOfPages /></span>
                  </div>
                  <div className="flex items-center px-2 border-gray-300">
                    <CurrentScale>
                      {(props: RenderCurrentScaleProps) => {
                        // Verifica se il livello di zoom corrente è presente nelle opzioni di zoom
                        if (!zoomLevels.includes(props.scale)) {
                          // Se non lo è, aggiungi il livello di zoom corrente alle opzioni di zoom
                          zoomLevels.push(props.scale);
                          // Ordina le opzioni di zoom in ordine crescente
                          zoomLevels.sort((a, b) => a - b);
                        }

                        return (
                          <select
                            value={props.scale}
                            onChange={(event) => {
                              let selectedValue = parseFloat(event.target.value);
                              // Se il valore selezionato è inferiore a 1, impostalo a 1
                              if (selectedValue < 1) {
                                selectedValue = 1;
                              }
                              zoomPluginInstance.zoomTo(selectedValue);
                            }}
                            className="border border-gray-300 rounded p-1"
                          >
                            {zoomLevels.map((level) => (
                              // Non generare un'opzione se il livello di zoom è 0
                              level !== 0 && (
                                <option key={level} value={level}>
                                  Zoom: {Math.round(level * 100)}%
                                </option>
                              )
                            ))}
                          </select>
                        );
                      }}
                    </CurrentScale>
                  </div>
                  <div className="flex items-center px-2 border-r border-gray-300">
                    <ZoomOut>
                      {(props: RenderZoomOutProps) => (
                        <button
                          className="border-none rounded cursor-pointer p-2"
                          onClick={props.onClick}
                        >
                          <AiOutlineMinusSquare size={20} />
                        </button>
                      )}
                    </ZoomOut>
                    <ZoomIn>
                      {(props: RenderZoomInProps) => (
                        <button
                          className="border-none rounded cursor-pointer p-2"
                          onClick={props.onClick}
                        >
                          <AiOutlinePlusSquare size={20} />
                        </button>
                      )}
                    </ZoomIn>
                  </div>
                  <div className="flex items-center px-2">
                    <Download>
                      {(props: RenderDownloadProps) => (
                        <button
                          className="bg-white border border-gray-300 rounded cursor-pointer p-2"
                          onClick={props.onClick}
                        >
                          Scarica PDF
                        </button>
                      )}
                    </Download>
                  </div>
                </>
              );
            }}
          </Toolbar>
        </div>
        <div className="h-[860px]">
          <Viewer
            // fileUrl={blobUrl}
            fileUrl={fileUrl}
            defaultScale={SpecialZoomLevel.PageFit}
            plugins={[toolbarPluginInstance, getFilePluginInstance, selectionModePluginInstance,zoomPluginInstance]}
            renderError={renderError}
          />
        </div>
      </Worker>
    </div>
  );
};

export default PDFViewer;
