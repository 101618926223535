import useAxios from '@hooks/api/useAxios';
import useChatHistory from '@stores/chatHistoryStore/chatHistory';
import { ModelState } from '@stores/modelStore/useModelsStore';
import { AxiosResponse } from 'axios';

type ChatData = {
  title: string;
  user?: number;
  thread_id?: string;
  llm_model: ModelState;
};

type RemoteChainData = {
  message_content: string;
  thread_id: string;
};

export type ChatMessageData = {
  role: 'human' | 'ai';
  message: string;
  llm_model: ModelState;
};


type ChatService = {
  getAllChats: () => Promise<AxiosResponse>;
  createChat: (data: ChatData) => Promise<AxiosResponse>;
  createRemoteChainV3: (data: RemoteChainData) => Promise<Response>;
  getChatById: (id: number) => Promise<AxiosResponse>;
  deleteChatById: (id: number) => Promise<AxiosResponse>;
  updateChatById: (id: number, data: ChatData) => Promise<AxiosResponse>;
  createChatMessage: (
    chatId: number,
    data: ChatMessageData
  ) => Promise<AxiosResponse>;
  deleteChatMessage: (
    chatId: number,
    messageId: number,
  ) => Promise<AxiosResponse>;
  getAllChatMessages: (chatId: number) => Promise<AxiosResponse>;
};

const useChatService = (): ChatService => {
  const signal = useChatHistory((state) => state.cancelTokenRemoteChainV3.signal);
  const api = useAxios();


  const getAllChats = (): Promise<AxiosResponse> => api.get('/api/chat/');

  const createChat = (data: ChatData): Promise<AxiosResponse> =>
    api.post('/api/chat/', data);

  // Pass the custom baseURL in the configuration of this specific request
  const  createRemoteChainV3 = async (data: RemoteChainData): Promise<Response> =>
     fetch(`${import.meta.env.VITE_REMOTE_CHAIN_URL_V3}/rag_chain_v3`, {
      signal: signal,
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/event-stream',
      }
    });

  const getChatById = (id: number): Promise<AxiosResponse> =>
    api.get(`/api/chat/${id}`);

  const deleteChatById = (id: number): Promise<AxiosResponse> =>
    api.delete(`/api/chat/${id}`);

  const updateChatById = (id: number, data: ChatData): Promise<AxiosResponse> =>
    api.put(`/api/chat/${id}`, data);

  const createChatMessage = (
    chatId: number,
    data: ChatMessageData,
  ): Promise<AxiosResponse> => api.post(`/api/chat/${chatId}/messages`, data);

  const deleteChatMessage = (
    chatId: number,
    messageId: number,
  ): Promise<AxiosResponse> =>
    api.delete(`/api/chat/${chatId}/messages/${messageId}`);

  const getAllChatMessages = (chatId: number): Promise<AxiosResponse> =>
    api.get(`/api/chat/${chatId}/messages`);

  return {
    getAllChats,
    createChat,
    createRemoteChainV3,
    getChatById,
    deleteChatById,
    updateChatById,
    createChatMessage,
    deleteChatMessage,
    getAllChatMessages,
  };
};

export default useChatService;
