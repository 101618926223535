import { useState } from 'react';
import ChatFooter from '@components/ChatFooter';
import ChatTopBar from '@components/ChatTopbar';
import PromptMenu from '@components/PromptMenu';
import KnowledgebaseChat from '@components/KnowledgebaseChat';
import useChangeLayout from '@services/assistenteLayout';
import InitialChatLayout from '@components/InitialChatLayout';
// import { useChatArchive } from '@services/chatArchive/useChatArchive';

const MainChatPage = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState<string>('All the prompts');
  const layout = useChangeLayout((state) => state.layout);
  // const { chats } = useChatArchive();

  return (
    <div className="flex flex-col h-full">
      {layout === 'promptMenu' && (
        <>
          <ChatTopBar activeTab={activeTab} setActiveTab={setActiveTab} />
          <div className="flex-grow flex h-full overflow-auto w-full flex-col">
            <PromptMenu activeTab={activeTab} />
          </div>
        </>
      )}
      {layout === 'default' && (
        <div className="flex-grow flex h-full overflow-auto w-full flex-col">
          <InitialChatLayout />
        </div>
      )}
      {layout === 'chat' && (
        <div className="flex-grow overflow-hidden">
          <KnowledgebaseChat />
        </div>
      )}

      <ChatFooter />
    </div>
  );
};

export default MainChatPage;
