import { PDFDocument, rgb } from "pdf-lib";
import { Chunk } from "../models/chunk";
import { TopicReport } from "../models/topic";
import { MyDocument } from "../models/document";
import { ChunkInterface } from "@services/report/reportsService";



/**
 * Retrieves an array of relevant chunks from a list of topic reports.
 *
 * @param topicTitle
 * @param {TopicReport[]} topicReports - The list of topic reports.
 * @return {any[]} An array of relevant chunks.
 */
export const getRelevantChunks = (topicTitle: string, topicReports: TopicReport[]): { chunks: number[][], report: TopicReport | undefined } => {
  const relevantsChunksArray: number[][] = [];
  const topicReportFiltered = topicReports.find((report: TopicReport) => report.topic_title === topicTitle);
  // Se non esiste un topic_report corrispondente, ritorna un array vuoto
  if (!topicReportFiltered) {
    console.error(`No topic report found for topic title "${topicTitle}"`);
  }
  if (topicReportFiltered) {
    if (topicReportFiltered.highlights && topicReportFiltered.highlights?.length > 0) {
      if (topicReportFiltered.relevant_chunks) {
        relevantsChunksArray.push(topicReportFiltered.relevant_chunks);
      }
    }
  }
  return { chunks: relevantsChunksArray, report: topicReportFiltered };
};

/**
 * Retrieves an array of chunks from a given list of relevant chunk IDs and a list of documents.
 *
 * @param relevantsChunksArray
 * @param {MyDocument[]} documentsReport - An array of documents containing chunks.
 * @return {Chunk[]} An array of chunks that are considered relevant based on the provided chunk IDs.
 */
export const getChunksArray = (relevantsChunksArray: number[][], documentsReport: MyDocument[]): Chunk[] => {
  let chunksToView: Chunk[] = [];
  documentsReport.forEach((el) => {
    chunksToView = [
      ...chunksToView,
      ...el.chunks.filter((el) => {
        if (relevantsChunksArray && relevantsChunksArray.flat().includes(el.chunk_id)) return el;
      }),
    ];
  });
  return chunksToView
};

/**
 * Generates a PDF with highlights based on the provided file and chunks to view.
 *
 * @param {File} file - The file to generate the PDF from.
 * @param {Chunk[]} chunksToView - The chunks to highlight in the PDF.
 * @return {string} The URL of the generated PDF.
 */
export const createPDFWithHighlights = async (file: ArrayBuffer, chunksToView: ChunkInterface[]): Promise<string> => {
  // const typedArray = new Uint8Array(await file.arrayBuffer());
  const myPdf = await PDFDocument.load(file);
  const pages = myPdf.getPages();
  chunksToView.forEach((element) => {
    const [x1, y1, x2, y2] = element.bbox;
    pages[element.page_id - 1].drawRectangle({
      x: x1 - 2.5, // remove half padding
      y: y1 - 2.5, // Invert y-coordinate as PDF coordinate system starts from bottom-left
      width: x2 - x1 + 5, // add padding
      height: y2 - y1 + 5,
      color: rgb(0.92, 0, 0.16), // Yellow color for highlighting rgb(1, 1, 0)
      opacity: 0.4,
      borderColor: rgb(0.92, 0, 0.16),
      borderWidth: 1,
    });
  });
  const pdfBytes = await myPdf.save();
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  return URL.createObjectURL(blob);
}

export const getFileDetails = (fileUrl: string): { name: string | undefined; type: string } => {
  const fileName = fileUrl.split('/').pop();
  const fileType = fileName?.split('.').pop()?.toUpperCase();
  return {
    name: fileName,
    type: fileType === 'PDF' || fileType === 'DOCX' ? fileType : 'Altro',
  };
};