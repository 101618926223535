import { create } from 'zustand';
import axios from 'axios';

export interface TranscriptSummaryInterface {
  topic_title: string;
  summary: string;
}

export interface TranscriptTopicDetailInterface {
  topic_title: string;
  qa_groups: TranscriptQAGroupInterface[];
}

export interface TranscriptQAGroupInterface {
  qa_group_id: number;
  is_relevant: boolean;
  question_relevance: string;
  summary: string;
}

export interface DocumentElementInterface {
  element_id: number;
  text: string;
  bbox: number[];
  type: string;
  page: number;
  category: string;
  qa_group: number;
}

export interface TranscriptInterface {
  peer: string;
  parsed_document: DocumentElementInterface[];
  qa_groups_analysis: TranscriptTopicDetailInterface[];
  summaries: TranscriptSummaryInterface[];
}

export interface TranscriptQuestionOutputInterface {
  topic_title: string;
  summary: string;
  relevant_elements: DocumentElementInterface[];
}

export interface TranscriptOutputState {
  questions: TranscriptQuestionOutputInterface;
  setQuestions: (peer: string, topic: string) => void;
}

export const useTranscriptFilter = create<TranscriptOutputState>((set) => ({
  questions: {
    topic_title: '',
    summary: '',
    relevant_elements: [],
  },
  setQuestions: async (peer: string, topic: string): Promise<void> => {
    const transcript: TranscriptInterface = await loadTranscript(peer);
    if(!transcript.summaries) {
      set({
        questions: {
          topic_title: topic,
          summary: '',
          relevant_elements: [],
        },
      });
      return;
    }
    const summary: TranscriptSummaryInterface | undefined =
      transcript.summaries?.find(
        (s: TranscriptSummaryInterface) =>
          s.topic_title.toLowerCase() === topic.toLowerCase(),
      );

    const topic_details = transcript.qa_groups_analysis?.find(
      (t: TranscriptTopicDetailInterface) =>
        t.topic_title.toLowerCase() === topic.toLowerCase(),
    );

    const groups = topic_details?.qa_groups?.filter(g => g.is_relevant);
    if (!groups) {
      return;
    }

    const qa_group_ids = groups.map((g: TranscriptQAGroupInterface) => g.qa_group_id);
    const elements: DocumentElementInterface[] =
      transcript.parsed_document?.filter((e: DocumentElementInterface) =>
        qa_group_ids.includes(e.qa_group),
      );

    const uniqueElements = elements?.reduce((acc, element) => {
      if (!acc.some(e => e.element_id === element.element_id)) {
        acc.push(element);
      }
      return acc;
    }, [] as DocumentElementInterface[]);

    const sortedElements = uniqueElements?.sort((a, b) => a.element_id - b.element_id);

    // Unisci le conversazioni consecutive
    const mergedElements = sortedElements?.reduce((acc, element) => {
      const lastElement = acc[acc.length - 1];

      if (lastElement && lastElement.category === 'conversation' && element.category === 'conversation') {
        lastElement.text += ' ' + element.text;
        lastElement.bbox.push(...element.bbox);
      } else {
        acc.push({ ...element, bbox: [...element.bbox] });
      }

      return acc;
    }, [] as DocumentElementInterface[]);

    set({
      questions: {
        topic_title: topic,
        summary: summary?.summary || '',
        relevant_elements: mergedElements,
      },
    });
  },
}));

const loadTranscript = async (topic: string): Promise<TranscriptInterface> => {
  const response = await axios.get(`/mock-apis/json/${topic}_transcript.json`);
  return response.data;
};
