import { create } from 'zustand';

export type ModelState = 'llm_gpt_4o_mod' | 'llm_llama_31_mod' | "llm_llama_31_70_mod"
export interface ModelStore {
  model: ModelState;
  setModel: (model: ModelState) => void;
}

const useModelStore = create<ModelStore>((set) => ({
  model: 'llm_llama_31_mod',
  setModel: (model: ModelState): void => set({ model }),
}));

export default useModelStore;