import CollapsedChatSidebar from '@components/CollapsedSidebar';
import ExpandedChatSidebar from '@components/ExpandedChatSidebar';
import MainChatPage from '@components/MainChatPage';
import { SetStateAction, useEffect, useState } from 'react';
import '@pages/Assistente/styles.css';
import { useChatArchive } from '@services/chatArchive/useChatArchive';
import { useChatArchiveFiltered } from '@services/chatArchiveFiltered/useChatArchiveFiltered';
import useChatService from '@services/chatService/useChatService';
import { useToastStore } from '@services/toast';
import { Splitter } from 'antd';

const Assistente = (): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState<boolean | undefined>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const setChats = useChatArchive((state) => state.setChats);
  const setFilteredChats = useChatArchiveFiltered(
    (state) => state.setFilteredChats,
  );
  const { getAllChats } = useChatService();
  const showToast = useToastStore((state) => state.showToast);
  const [sizes, setSizes] = useState<(number | string)[]>(['15%', '85%']);

  useEffect(() => {
    const fecthAllChatHistory = async (): Promise<void> => {
      try {
        const response = await getAllChats();
        setChats(response.data);
        setFilteredChats(response.data);
      } catch (error) {
        showToast('Error: An error occurred while retrieving all chats.');
        setChats([]);
        setFilteredChats([]);
      }
    };

    fecthAllChatHistory();
  }, []);

  const handlerCollapsed = (event: SetStateAction<boolean | undefined>): void => {
    setIsCollapsed(event);
    (event) ? setSizes(['2%', '98%']) : setSizes(['20%', '80%']);
  }

  return (
    <div className="flex pageWrap my-10 overflow-y-hidden">
      <Splitter
        onResize={setSizes}
        className='h-full noshadow'
        style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}
      >
        <Splitter.Panel size={sizes[0]} resizable={!isCollapsed}>
          <div className={`${isCollapsed ? 'w-full' : 'w-full'} h-full`}>
            {isCollapsed ? (
              <CollapsedChatSidebar setIsCollapsed={(event)=> handlerCollapsed(event)} />
            ) : (
              <ExpandedChatSidebar
                setIsCollapsed={(event)=> handlerCollapsed(event)}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            )}
          </div>
        </Splitter.Panel>
        <Splitter.Panel size={sizes[1]}>
          <div className={`${isCollapsed ? 'w-full h-full' : 'w-full h-full'}`}>
            <MainChatPage />
          </div>
        </Splitter.Panel>
      </Splitter>
    </div>
  );
};

export default Assistente;
