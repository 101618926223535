import { create } from 'zustand';
import { Chat } from '@services/chatArchive/useChatArchive';

interface ChatFilteredArchiveState {
  filteredChats: Chat[];
  addFilteredChat: (filteredChat: Chat) => void;
  setFilteredChats: (filteredChats: Chat[]) => void;
  updateChatFilteredTitle: (id: number, title: string) => void;
  deleteFilteredChat: (id: number) => void;
}

export const useChatArchiveFiltered = create<ChatFilteredArchiveState>(
  (set) => ({
    filteredChats: [],
    addFilteredChat: (filteredChat: Chat): void =>
      set((state) => ({
        filteredChats: [...state.filteredChats, filteredChat],
      })),
    setFilteredChats: (filteredChats: Chat[]): void => set({ filteredChats }),
    updateChatFilteredTitle: (id: number, title: string): void =>
      set((state) => ({
        filteredChats: state.filteredChats.map((chat) =>
          chat.id === id ? { ...chat, title } : chat,
        ),
      })),
    deleteFilteredChat: (id: number): void =>
      set((state) => ({
        filteredChats: state.filteredChats.filter((chat) => chat.id !== id),
      })),
  }),
);
