import { useEffect, useRef } from 'react';
import { IoMdSend } from 'react-icons/io';
import useSendMessages from '@hooks/useSendMessages';
import useChatHistory from '@stores/chatHistoryStore/chatHistory';

const ChatFooter = (): JSX.Element => {
  const {threadId, setNewThreadId} = useChatHistory((state)=>state);
  const { handleSubmit, onMsgChange, msg } = useSendMessages(); // custom hook to make the code more readable
  const promptArea = useRef<HTMLInputElement | null>(null);



  useEffect(() => {
    promptArea.current?.focus();
    setNewThreadId();
  }, []);

  return (
    <div className="flex flex-col w-full">
      {/*      <button className="p-2 px-3 border-2 border-greyLdo rounded-md ml-4 hover:bg-darkBlueLdo hover:border-darkBlueLdo hover:text-white text-darkBlueLdo">
        <IoIosAttach size={30} />
      </button>*/}
      <form
        onSubmit={(event)=>handleSubmit(event, threadId)}
        className="border-2 border-greyLdo shadow-lg w-11/12 mx-3 rounded-lg flex py-4 px-3 focus-within:border-darkBlueLdo"
      >
        <input
          type="textarea"
          value={msg}
          onChange={onMsgChange}
          ref={promptArea}
          className="outline-none px-4 flex-grow"
          placeholder="Write here your message..."
        />
        <button type="submit" className="mr-4 text-red-600">
          <IoMdSend size={24} />
        </button>
      </form>
      <div className="flex items-center justify-center content-center text-sm">
        <p>
          Please note that AI-generated responses may contain errors. Always
          double-check important information for accuracy.
        </p>
      </div>
    </div>
  );
};

export default ChatFooter;


