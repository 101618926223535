import CompanyNewsCard from '@components/CompanyNewsCard/CompanyNewsCard.tsx';

const data = [
  {
    company: "Thales",
    description: "invested €2.6 billion in R&D in H1 2024, focusing on AI, cybersecurity, and quantum technologies",
    category: "R&D Investment and Innovation",
    thumbsUp: true,
  },
  {
    company: "Safran",
    description: "improved its EBIT margin to 15.1% and increased operating profit by €0.5 billion through its Efficiency & Simplification program",
    category: "Cost Savings and Efficiency",
    thumbsUp: true,
  },
  {
    company: "BAE Systems",
    description: "completed the $5.5 billion acquisition of Ball Aerospace, strengthening its position in aerospace and defense",
    category: "M&A Activity and Market Expansion",
    thumbsUp: true,
  },
  {
    company: "General Dynamics",
    description: "secured a $1.9 billion contract with the Canadian Army, pushing its defense backlog to $71.3 billion",
    category: "Contract Wins",
    thumbsUp: true,
  },
  {
    company: "Northrop Grumman",
    description: "returned $2.3 billion to shareholders in H1 2024, including $304 million in dividends and a share buyback of 9.2 million",
    category: "Shareholder Returns",
    thumbsUp: true,
  },
  {
    company: "RTX Corporation",
    description: "incurred a $575 million pre-tax charge from contract termination and titanium supply chain issues due to sanctions",
    category: "Supply Chain Disruptions",
    thumbsUp: false,
  },
  {
    company: "Safran",
    description: "faced a 16.5% increase in raw material costs in H1 2024, including rising prices for titanium and other essential materials",
    category: "Inflation and Raw Material Price Hikes",
    thumbsUp: false,
  },
  {
    company: "Boeing",
    description: "slowed production rates due to persistent supply chain issues and resulting in a $4 billion charge for delayed orders",
    category: "Production Delays and Program Slowdowns",
    thumbsUp: false,
  },
  {
    company: "BAE Systems",
    description: "incurred an additional £1.2 billion in pension obligations due to inflation",
    category: "Labour Costs",
    thumbsUp: false,
  }
];

const NewsComponent = (): JSX.Element => {
  return (
    <div className="flex flex-col items-center"> {/* Centra il titolo e le card */}
      {/* Titolo */}
      <h2 className="text-gray-800 text-2xl font-bold text-center mb-4">Q2 – 2024 Latest Insights from Peers</h2>

      {/* Griglia delle card */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-4 justify-center max-w-6xl"> {/* Uniformato il gap */}
        {data.map((item, index) => (
          <CompanyNewsCard
            key={index}
            company={item.company}
            description={item.description}
            category={item.category}
            thumbsUp={item.thumbsUp}
          />
        ))}
      </div>
    </div>
  );
};

export default NewsComponent;
