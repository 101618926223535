import React from 'react';
import { Tag, Tooltip } from 'antd';
import { getLocaleDate } from '@utils/dateFormatter';
import { truncateString } from '@utils/stringUtils';

interface MetaDataTagsProps {
  quarter: string; // Esempio: "2024-Q2"
  files?: string[]; // Lista di file
  date?: string;
  extra?: string[];
}

const MetaDataTags: React.FC<MetaDataTagsProps> = ({
  quarter,
  files,
  date,
  extra,
}) => {
  // Colore predefinito per il quarter (rosso)
  const quarterColor = 'rgb(239, 68, 68)';

  // Colore predefinito per i file (grigio scuro)
  const fileTagStyle = {
    backgroundColor: 'rgb(155,155,159)',
    color: '#ffffff',
  };

  let dateString: string | null;
  date ? (dateString = getLocaleDate(date)) : (dateString = null);

  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {/* Quarter Tag */}
      <Tag style={{ backgroundColor: quarterColor, color: '#ffffff' }}>
        {quarter}
      </Tag>

      {/* File Tags con Tooltip per nomi lunghi */}
      {files &&
        files.map((file, index) => (
          file&&<Tooltip key={index} title={file}>
            <Tag style={fileTagStyle}>
              {truncateString(file, 30)}{' '}
              {/* Tronca il file se è più lungo di 30 caratteri */}
            </Tag>
          </Tooltip>
        ))}
      {dateString && (
        <Tooltip key={dateString} title={dateString}>
          <Tag style={fileTagStyle}>{truncateString(dateString, 10)}</Tag>
        </Tooltip>
      )}
      {extra &&
        extra.map((el, index) => (
          <Tooltip key={index} title={el}>
            <Tag style={fileTagStyle}>
              {truncateString(el, 30)}{' '}
              {/* Tronca il file se è più lungo di 30 caratteri */}
            </Tag>
          </Tooltip>
        ))}
    </div>
  );
};

export default MetaDataTags;
