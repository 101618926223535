import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PDFViewer from '@components/PDFViewer';
import PdfSectionTextarea from '@components/PdfSectionTextarea';
import { IoIosArrowBack, IoIosDocument } from 'react-icons/io';
import {
  createPDFWithHighlights,
  getFileDetails,
} from '@utils/createPDFWithHighlights.ts';
import { usePeersFilter } from 'stores/filters';
import useReportStore from '@stores/reportStore/useReportStore';
import usePdfService from '@services/pdfService/usePdfService';
import { Skeleton } from 'antd';
import { useToastStore } from '@services/toast';

const PdfViewer = (): JSX.Element => {
  const navigate = useNavigate();
  const [url, setUrl] = useState<string>('');
  // const [report, setReport] = useState<TopicReport | undefined>(undefined);
  // const { topic } = useTopicsFilter();
  const peer = usePeersFilter((state) => state.peer);
  const report = useReportStore((state) => state.report);
  const showToast = useToastStore((state) => state.showToast);

  const [fileDetails, setFileDetails] = useState<{
    name: string | undefined;
    type: string;
  }>({ name: '', type: '' });

  const { getPdf } = usePdfService();

  const getBlobFile = async (
    peerId: number,
    year: number,
    quarter: number,
    fileName: string,
    type?: string,
  ): Promise<Blob> => {
    const fileByte = await getPdf(peerId, year, quarter, fileName, type);
    return new Blob([fileByte.data], { type: 'application/pdf' });
  };

  const highlightText = async (): Promise<string> => {
    if (!peer) {
      return '';
    }
    let file;

    if (
      report?.companies[0] &&
      report?.year &&
      report?.quarter &&
      report?.documents[0] &&
      report?.type
    ) {
      file = await getBlobFile(
        report?.companies[0]?.id,
        report?.year,
        report?.quarter,
        report?.documents[0]?.name,
        report?.type,
      );
    }

    if (!file) {
      throw new Error('File loading failed');
    }

    const elementToView = report?.relevant_chunks;
    if (elementToView && file) {
      const arrayBuffer = await file.arrayBuffer();
      return await createPDFWithHighlights(arrayBuffer, elementToView);
    } else {
      return URL.createObjectURL(file);
    }
  };

  const fetchUrl = async (): Promise<void> => {
    try {
      const url = await highlightText();
      if (url) {
        setUrl(url);
      }
    } catch (error) {
      showToast('Error: failed to retrieve the file');
      console.error('Error highlighting text:', error);
    }
  };

  useEffect(() => {
    if (report?.documents[0]) {
      setFileDetails(getFileDetails(report?.documents[0]?.name));
    } else {
      setFileDetails(getFileDetails(''));
    }
    fetchUrl();
  }, []);

  return (
    <div className="p-6 mt-24">
      <div className="flex items-center mb-4">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-black"
        >
          <div className="flex items-center justify-center bg-gray-700 text-white rounded-full w-8 h-8 mr-4 ml-8">
            <IoIosArrowBack size={16} />
          </div>
          <span className="text-lg">Go back</span>
        </button>
      </div>
      <div className="max-w-[100rem] mx-auto">
        <h1 className="text-5xl font-bold text-center mb-6">
          Detect source analysis
        </h1>
        <p className="text-center text-2xl mb-20">
          Explore the analysis results: discover the origin of the data directly
          from the PDF paragraph.
        </p>
        <div className="flex justify-around mb-8">
          <div className="font-semibold text-xl">Analyzed document</div>
          <div className="font-semibold text-xl">Highlights</div>
        </div>
        <div className="flex gap-32">
          <div className="w-1/2">
            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <div className="flex  justify-between mb-4">
                <span className="text-sm">File name: {fileDetails?.name}</span>
                <span className="text-sm">File type: {fileDetails?.type}</span>
              </div>
            </div>
            <div className="border border-gray-300 rounded-lg max-h-[90rem]">
              {!url && (
                <Skeleton.Node active={true} className='!h-[30rem] !w-full'>
                  <IoIosDocument style={{ fontSize: 40, color: '#bfbfbf' }} />
                </Skeleton.Node>
              )}
              {url && <PDFViewer fileUrl={url} />}
            </div>
          </div>
          <div className="w-1/2 max-h-[90rem]">
            <PdfSectionTextarea
              title={report?.title}
              content={report ? report.report : ''}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
