import { create } from 'zustand';
import { SynthReport } from '@models/synthReport';
import { SingleNews } from '@models/news';

export interface SynthReportState {
  synthReport: SynthReport;
  setSynthReport: (synthReport: SynthReport) => void;
  news: SingleNews[]
  setNews: (news: SingleNews[]) => void;
}

 const useSynthReportStore = create<SynthReportState>(
  (set): SynthReportState => ({
    synthReport: {
      highlights: [],
      highlights_grouped: [],
      reports: [],
      transcripts: [],
    },
    setSynthReport: async (synthReport: SynthReport): Promise<void> => {
      set({ synthReport: synthReport });
    },
    news: [],
    setNews: async (news: SingleNews[]): Promise<void> => {
      set({ news: news });
    },
  }),
);

export default useSynthReportStore;