export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const getDateCategory = (dateString: string): string => {
  const currentDate = new Date();
  const chatDate = formatDate(dateString);
  const yesterday = formatDate(
    new Date(currentDate.getTime() - 24 * 60 * 60 * 1000).toISOString(),
  );
  const lastWeek = formatDate(
    new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString(),
  );

  if (chatDate === formatDate(currentDate.toISOString())) {
    return 'Today';
  } else if (chatDate === yesterday) {
    return 'Yesterday';
  } else if (chatDate === lastWeek) {
    return 'Last Week';
  } else {
    return 'Older';
  }
};

export const getLocaleDate = (dateString: string): string => {
  // Creare un oggetto Date a partire dalla stringa ISO
  const date = new Date(dateString);

  // Opzioni per il formato italiano
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'Europe/Rome', // Fuso orario italiano
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };


  const formattedDate = date.toLocaleString('it-IT', options);
  return formattedDate
};
