import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Report } from '@models/synthReport';

export interface ReportCurrentStore {
  report: Report | null;
  setReport: (report: Report) => void;
  clearReport: () => void;
}

const useReportStore = create<ReportCurrentStore>()(
  persist(
    (set) => ({
      report: null,
      setReport: (report: Report): void => set({ report }),
      clearReport: (): void => set({ report: null }),
    }),
    {
      name: 'report', // Nome della chiave nel localStorage
      storage: {
        getItem: (name) => {
          const item = localStorage.getItem(name);
          return item ? JSON.parse(item) : null;
        },
        setItem: (name, value) => {
          localStorage.setItem(name, JSON.stringify(value));
        },
        removeItem: (name) => {
          localStorage.removeItem(name);
        },
      },
    }
  )
);

export default useReportStore;
