import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import '@common/Navbar/ToggleMenu/styles.css';

const ToggleButton = (): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let pathSelected = 'Report';
  let moveClass = '';
  switch (pathname) {
    case '/report':
      pathSelected = 'Report';
      moveClass = 'translate-x-0';
      break;
    case '/assistente':
      pathSelected = 'Assistant';
      moveClass = 'translate-x-full';
      break;
    case '/signals':
      pathSelected = 'Signals';
      moveClass = 'translate-x-200';
      break;
    default:
      if(pathname.includes('/signal')) {
        pathSelected = 'Signals';
        moveClass = 'translate-x-200';
      }
  }

  return (
    <div className="flex items-center justify-center">
      <div className="flex items-center justify-between w-96 h-9 bg-white rounded-full cursor-pointer relative">
        <NavLink to="/report" className="w-1/3 text-center font-bold">
          Report
        </NavLink>
        <NavLink to="/assistente" className="w-1/3 text-center font-bold">
          Assistant
        </NavLink>
        <NavLink to="/signals" className="w-1/3 text-center font-bold">
          Signals
        </NavLink>
        <div
          onClick={() => navigate(pathname.includes('/signal')? '/signals' : pathname)}
          className={`absolute h-full w-1/3 bg-redLdo shadow-xl rounded-full transition-transform duration-300 ease-in-out flex items-center justify-center text-white font-bold ${
            moveClass
          }`}
        >
          {pathSelected}
        </div>
      </div>
    </div>
  );
};

export default ToggleButton;
