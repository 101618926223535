// src/hooks/useCopyToClipboard.ts
import { usePopupInfo } from '@services/popupInfo/usePopupInfo';

const useCopyToClipboard = (): {
  copyToClipboard: (text: string) => Promise<void>;
} => {
  const showPopup = usePopupInfo((state) => state.showPopup);

  const copyToClipboard = async (text: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(text);
      showPopup('Text copied!');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return { copyToClipboard };
};

export default useCopyToClipboard;
