import SearchInput from '@components/SearchInput';
import { GoSidebarExpand } from 'react-icons/go';
import { RiChatNewLine } from 'react-icons/ri';
import { ChangeEvent, useMemo, useRef, useEffect } from 'react';
import ChatArchive from '@components/ChatArchive';
import useChangeLayout from '@services/assistenteLayout';
import { useChatArchive } from '@services/chatArchive/useChatArchive';
import useChatHistory, {
  StreamMessage,
} from '@stores/chatHistoryStore/chatHistory';
import { useChatArchiveFiltered } from '@services/chatArchiveFiltered/useChatArchiveFiltered';
import { Select, Tabs, TabsProps } from 'antd';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import preprocessContent from '@utils/preprocessContent';
import '@components/ExpandedChatSidebar/styles.css';
import useModelStore from '@stores/modelStore/useModelsStore';
import { v4 as uuidv4 } from 'uuid';


interface ExpandedChatSidebarProps {
  setIsCollapsed: (isCollapsed: boolean) => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
}

const ExpandedChatSidebar = ({
  setIsCollapsed,
  searchTerm,
  setSearchTerm,
}: ExpandedChatSidebarProps): JSX.Element => {
  const { chats } = useChatArchive();
  const { filteredChats, setFilteredChats } = useChatArchiveFiltered();
  const setLayout = useChangeLayout((state) => state.setLayout);
  const { model, setModel } = useModelStore((state) => state);
  const {
    clearStreamChatMessages,
    clearChatHistory,
    setNewThreadId,
    streamChatMessages,
    setCancelTokenRemoteChainV3,
    cancelTokenRemoteChainV3
  } = useChatHistory((state) => state);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
    if (event.target.value === '') {
      setFilteredChats(chats);
      return;
    }
    const newFilteredChats = chats.filter((chat) =>
      chat.title.toLowerCase().includes(event.target.value.toLowerCase()),
    );
    setFilteredChats(newFilteredChats);
  };

  const goToDefaultSection = (): void => {
    setLayout('default');
    cancelTokenRemoteChainV3.abort();
    setCancelTokenRemoteChainV3();
    setNewThreadId();
    clearStreamChatMessages();
    clearChatHistory();
  };

  const goToPromptLib = (): void => {
    setLayout('promptMenu');
    cancelTokenRemoteChainV3.abort();
    setCancelTokenRemoteChainV3()
    setNewThreadId();
    clearStreamChatMessages();
    clearChatHistory();
  };

  const SectionArchive = (): JSX.Element => {
    return (
      <>
        <div className="rounded-r-3xl p-1 my-1">
          <SearchInput value={searchTerm} onChange={handleSearch} />
        </div>
        <ChatArchive filteredChats={filteredChats} />
      </>
    );
  };

  const SectionLogs = (): JSX.Element => {
    const logs = useMemo(() => {
      const seen = new Set();
      return streamChatMessages.filter(
        (message) =>
          message.type === 'log' &&
          !seen.has(message.content) &&
          seen.add(message.content),
      );
    }, []);

    const logsContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (logsContainerRef.current) {
        logsContainerRef.current.scrollTop = logsContainerRef.current.scrollHeight;
      }
    }, [logs]);

    return (
      <div
        ref={logsContainerRef}
        className="rounded-md p-4 my-4 overflow-y-auto h-full bg-white border border-gray-200 shadow-md"
      >
        {logs.length > 0 ? (
          logs.map((message: StreamMessage) => (
            <div
              key={`stream-message-${message.id}-${uuidv4()}`}
              className="p-4 mb-3 rounded-lg bg-gray-50 hover:bg-gray-100 transition-shadow duration-300 border-l-4 border-red-500 shadow-sm"
            >
              <div className="flex items-start space-x-2">
              <span className="font-semibold text-sm text-red-600">
                Log:
              </span>
                <Markdown
                  remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                  rehypePlugins={[rehypeRaw]}
                  className="prose text-sm"
                >
                  {preprocessContent(message.content)}
                </Markdown>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-center">Log not available</p>
        )}
      </div>
    );
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span className="font-bold text-lg text-darkBlueLdo p-1 mb-2">
          Archive
        </span>
      ),
      children: <SectionArchive />,
      className: 'h-full flex flex-col w-full',
    },
    {
      key: '2',
      label: (
        <span className="font-bold text-lg text-darkBlueLdo p-1 mb-2">
          Logs
        </span>
      ),
      children: <SectionLogs />,
      className: 'h-full flex flex-col w-full',
    },
  ];

  return (
    <div
      className="flex flex-col h-full rounded-r-3xl border border-solid"
    >
      <div className="m-2 p-2 flex justify-end">
        <button
          className="border-2 border-darkBlueLdo bg-[#39414C] rounded-lg p-2 mr-2  hover:bg-[#EB0029] text-white"
          onClick={() => setIsCollapsed(true)}
        >
          <GoSidebarExpand size={22} />
        </button>
        <button
          className="border-2 border-darkBlueLdo bg-[#39414C] rounded-lg p-2 mr-2  hover:bg-[#EB0029] text-white"
          onClick={goToDefaultSection}
        >
          <RiChatNewLine size={22} />
        </button>
      </div>
      <div
        className="border-2 bg-[#39414C] border-darkBlueLdo text-white rounded mx-4 py-2 my-1 cursor-pointer  hover:bg-[#EB0029] hover:text-white font-medium justify-center flex items-center"
        onClick={goToPromptLib}
      >
        Open prompt library
      </div>
      <Select
        className="mx-4 my-1 font-medium"
        defaultValue={model}
        onChange={setModel}
        options={[
          { value: 'llm_llama_31_mod', label: 'Llama 3.1 405B' },
          { value: 'llm_gpt_4o_mod', label: 'GPT 4o' },
        ]}
      />
      <div className="flex flex-col flex-grow h-0 overflow-hidden">
        <div className="flex flex-col p-4 flex-grow h-full">
          <Tabs
            className="my-sidebar-tabs h-full flex"
            defaultActiveKey="1"
            items={items}
          />
        </div>
      </div>
    </div>
  );
};

export default ExpandedChatSidebar;
