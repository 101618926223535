import { create } from 'zustand';

export interface LayoutState {
  layout: string;
  setLayout: (LayoutToChange: string) => void;
}

export const useChangeLayout = create<LayoutState>(
  (set): LayoutState => ({
    layout: 'default',
    setLayout: (layoutToChange: string) => set({ layout: layoutToChange }),
  }),
);

export default useChangeLayout;
