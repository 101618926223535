import SystemIcon from '@assets/SystemIcon.svg';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { IoCopyOutline } from 'react-icons/io5';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import '@components/SystemMessage/styles.css';
import preprocessContent from '@utils/preprocessContent';
import MetaLogo from '@assets/meta.png';
import ChatGPTLogo from '@assets/ChatGPT-Logo.png';
import { Avatar } from 'antd';
import { ModelState } from '@stores/modelStore/useModelsStore';

const SystemMessage = ({
  message,
  model,
}: {
  message: string;
  model?: ModelState;
}): JSX.Element => {
  const { copyToClipboard } = useCopyToClipboard();

  const processedContent = preprocessContent(message);

  let img = MetaLogo
  if(model){
    switch (model) {
      case 'llm_llama_31_mod':
        img = MetaLogo
        break
      case 'llm_gpt_4o_mod':
        img = ChatGPTLogo
        break 
    }
  }

  return (
    <div className="flex justify-start px-2 py-4">
      <div className="flex items-start font-normal">
        <img src={SystemIcon} className="w-20 mr-4" />
        <div className="flex flex-col w-full">
          <div className="flex w-full">
            <div className="message-sent border bg-[#444654] text-white p-2 px-7 w-full overflow-x-auto mb-2 rounded-xl rounded-bl-none flex justify-between">
              <Markdown
                remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                rehypePlugins={[rehypeRaw]}
                className="prose prose-invert mr-4"
              >
                {processedContent}
              </Markdown>
              {model && (
                <Avatar
                  style={{ backgroundColor: '#fff' }}
                  src={img}
                />
              )}
            </div>
            {processedContent === '...' && (
              <AiOutlineLoading3Quarters className="ai-spinner" />
            )}
          </div>

          <div className="flex justify-end mt-2">
            <button
              className="bg-white p-2 rounded-lg hover:opacity-50"
              onClick={() =>
                copyToClipboard(processedContent?.toString() ?? '')
              }
            >
              <IoCopyOutline />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SystemMessage;
