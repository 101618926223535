import { create } from 'zustand';

export interface chatHistoryState {
  chatMessage: string;
  setChatMessage: (message: string) => void;
  clearChatMessage: () => void;
}

export const useChatMessage = create<chatHistoryState>(
  (set): chatHistoryState => ({
    chatMessage: '',
    setChatMessage: (message: string) => set({ chatMessage: message }),
    clearChatMessage: () => set({ chatMessage: '' }),
  }),
);

export default useChatMessage;
