import { usePeersFilter, useTopicsFilter } from 'stores/filters';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useQuartersFilter } from '@stores/filters/useQuartersFilters.ts';
import NewsComponent from '@components/NewsComponent/NewsComponent.tsx';

const AnalysisStatus = (): JSX.Element => {
  const selectedPeer = usePeersFilter((state) => state.peer);
  const selectedDropdown = useTopicsFilter((state) => state.topic);
  const selectedQuarter = useQuartersFilter((state) => state.quarter);
  const clearPeerFilter = usePeersFilter((state) => state.clearPeer);
  const clearTopicFilter = useTopicsFilter((state) => state.clearTopic);
  const clearQuarterFilter = useQuartersFilter((state) => state.clearQuarter);

  return (
    <>
      {!selectedPeer && !selectedDropdown && !selectedQuarter && (
        <div className=" w-full text-center mt-20">
          <NewsComponent />
        </div>
      )}
      {(selectedPeer || selectedDropdown || selectedQuarter) && (
        <div className="pl-12 flex items-center self-end">
          Analysis completed:
          {selectedPeer && (
            <div
              className="bg-darkBlueLdo rounded-full p-2 pl-6 pr-3 text-sm font-medium text-white ml-4 flex justify-center items-center">
              {selectedPeer.name}
              <IoIosCloseCircleOutline
                size={20}
                className="ml-1 cursor-pointer"
                onClick={() => clearPeerFilter()}
              />
            </div>
          )}
          {selectedDropdown && <div
            className="bg-darkBlueLdo rounded-full py-2 pl-6 pr-3 text-sm ml-4 font-medium text-white flex justify-center items-center">
            {selectedDropdown.name}
            <IoIosCloseCircleOutline
              size={20}
              className="ml-1 cursor-pointer"
              onClick={() => clearTopicFilter()}
            />
          </div>}
          {selectedQuarter && <div
            className="bg-darkBlueLdo rounded-full py-2 pl-6 pr-3 text-sm ml-4 font-medium text-white flex justify-center items-center">
            {selectedQuarter?.name}
            <IoIosCloseCircleOutline
              size={20}
              className="ml-1 cursor-pointer"
              onClick={() => clearQuarterFilter()}
            />

          </div>}
        </div>
      )}
    </>
  );
};

export default AnalysisStatus;
