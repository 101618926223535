import { create } from 'zustand';
import { InfoTypes, NewsGroups, Signal, SignalGroups, SingleNews } from '@models/news';



export interface SignalsState {
  signals: SignalGroups;
  setSignals: (type: InfoTypes, signals: Signal[]) => void;
  news: NewsGroups;
  setNews: (type: InfoTypes, news: SingleNews[]) => void;
  timestamp: number;
  setTimestamp: (timestamp: number) => void;
}

 const useSynthReportStore = create<SignalsState>(
  (set): SignalsState => ({
    signals: {
      signalsPeer: [],
      signalsIndustry: [],
    },
    setSignals: async (type: InfoTypes, newSignals: Signal[]): Promise<void> => {
      set((state) => 
        (type === 'Industry') ? { signals: { signalsPeer: state.signals.signalsPeer, signalsIndustry: [...newSignals] } } :
        { signals: { signalsIndustry: state.signals.signalsIndustry, signalsPeer: [...newSignals] } }
      );
    },
    news: {
      newsPeer: [],
      newsIndustry: [],
    },
    setNews: async (type: InfoTypes, newNews: SingleNews[]): Promise<void> => {
      set((state) => 
        (type === 'Industry') ? { news: { newsPeer: state.news.newsPeer, newsIndustry: [...newNews] } } :
        { news: { newsIndustry: state.news.newsIndustry, newsPeer: [...newNews] } }
      );
    },
    timestamp: 0,
    setTimestamp: async (timestamp: number): Promise<void> => {
      set({ timestamp });
    },
  }),
);

export default useSynthReportStore;