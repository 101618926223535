import React, { ChangeEvent, useEffect, useState } from 'react';
import { usePeersFilter } from 'stores/filters';
import SearchInput from '@components/SearchInput';
import { Peer } from '@models/peer';
import usePeerService from '@services/peerService/usePeerService';
import { getPeerLogo } from '@utils/getPeerLogo';

const SidebarPeerSearchFilter: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [peers, setPeers] = useState<Peer[]>([]);
  const [filteredPeers, setFilteredPeers] = useState<Peer[]>([]);

  const { getAllPeers } = usePeerService();

  const peerFilter = usePeersFilter((state) => state.peer);
  const setPeerFilter = usePeersFilter((state) => state.setPeer);
  const clearPeerFilter = usePeersFilter((state) => state.clearPeer);


  useEffect(() => {
    // Richiama la funzione getAllPeers quando il componente viene montato
    getAllPeers().then((response: { data: React.SetStateAction<Peer[]> }) => {
      setPeers(response.data);
      setFilteredPeers(response.data);
    });
  }, []);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    setFilteredPeers(peers.filter((peer) => peer.name.toLowerCase().includes(newSearchTerm.toLowerCase())));
  };

  const handleClickPeer = (id: number): void => {
    if (peerFilter?.id === id) {
      clearPeerFilter();
    } else {
      const filteredPeer = peers.find((peer) => peer.id === id);
      setPeerFilter(filteredPeer ? filteredPeer : null);
    }
  };

  return (
    <div className="w-96 border-solid shadow-xl mt-3 rounded-r-3xl border flex-shrink-0 flex flex-col">
      <div className="p-4 px-6">
        <h4 className="text-lg font-semibold mb-4">Filter by reference peers:</h4>
        <div className="mb-4">
          <SearchInput value={searchTerm} onChange={handleSearch} />
        </div>
      </div>
      <div className="flex-grow overflow-auto">
        <div className="pt-2 pb-4">
          <ul id="dropdown-scroll" className="mt-4 space-y-2">
            {filteredPeers
              .sort((a, b) => a.name.localeCompare(b.name)) // order by alphabetic order
              .map((peer) => (
                <li
                  key={peer.id}
                  className={`px-4 py-2 text-sm cursor-pointer flex items-center justify-center w-full mb-2 ${
                    peerFilter?.id === peer.id
                      ? 'bg-gray-800 text-white font-bold'
                      : 'text-gray-700 hover:text-black hover:font-bold'
                  }`}
                  data-value={peer.name}
                  onClick={() => handleClickPeer(peer.id)}
                  style={{ position: 'relative' }} // Ensure proper positioning for z-index
                >
                  <div className="w-2/3 flex items-center">
                    {getPeerLogo(peer.name) ? (
                      <img
                        src={getPeerLogo(peer.name)!}
                        alt={peer.name}
                        className="rounded-full w-10 h-10 me-4 bg-white"
                        style={{ zIndex: 10, position: 'relative' }} // Ensure image stays on top
                      />
                    ) : (
                      <div className="rounded-full w-10 h-10 bg-[#E3E4E9] me-4" style={{ zIndex: 10, position: 'relative' }} />
                    )}
                    <div className="ml-2 text-[medium]">{peer.name}</div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SidebarPeerSearchFilter;
