import { create } from 'zustand';

interface Tag {
  id: number;
  name: string;
}

interface Prompt {
  id: number;
  created_at: string;
  modified_at: string;
  title: string;
  description: string;
  created_by: number;
  modified_by: number;
  is_pinned: boolean;
  tags: Tag[];
}

interface PromptStore {
  prompts: Prompt[];
  allPrompts: Prompt[]; // Stato per memorizzare tutti i prompt ordinati
  page: number;
  hasMore: boolean;
  setPrompts: (prompts: Prompt[], isLatestTab?: boolean) => void;
  loadMorePrompts: () => void;
  resetPrompts: () => void;
}

const usePromptStore = create<PromptStore>((set, get) => ({
  prompts: [],
  allPrompts: [],
  page: 1,
  hasMore: true,

  // Imposta i prompt ordinati e gestisce le diverse tab
  setPrompts: (prompts: Prompt[], isLatestTab = false): void => {
    const itemsPerPage = isLatestTab ? 3 : prompts.length;
    set({
      allPrompts: prompts,
      prompts: prompts.slice(0, itemsPerPage),
      page: 1,
      hasMore: prompts.length > itemsPerPage
    });
  },

  resetPrompts: (): void => set({ prompts: [], allPrompts: [], page: 1, hasMore: true }),

  loadMorePrompts: (): void => {
    const { page, allPrompts, prompts } = get();
    const itemsPerPage = 3;
    const newPage = page + 1;

    // Estrarre i prossimi prompt da aggiungere
    const additionalPrompts = allPrompts.slice(page * itemsPerPage, newPage * itemsPerPage);
    const updatedPrompts = [...prompts, ...additionalPrompts];

    // Verifica se ci sono ancora prompt da caricare
    const hasMore = updatedPrompts.length < allPrompts.length;

    set({
      prompts: updatedPrompts,
      page: newPage,
      hasMore: hasMore,
    });
  },
}));

export default usePromptStore;
