import useNewsService from '@services/newsService/useNewsService';
import useSignalsStore from '@stores/signalsStore/useSignalsStore';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { InfoTypes, Signal, SingleNews } from '@models/news';
import SignalSection from '@components/SignalSection/signalSection';
import {formatText} from '@utils/newsFormatter';


const SignalPeerContainer = (  ): JSX.Element => {
  const {  getOtherNews, getOtherSignals } = useNewsService();

  const { signals, setSignals, news, setNews, setTimestamp, timestamp } =
    useSignalsStore((state) => state);
  const [loadingSignals, setLoadingSignals] = useState<boolean>(true);
  const [loadingNews, setLoadingNews] = useState<boolean>(true);
  const infoType: InfoTypes = 'Industry';

  useEffect(() => {
      if (
        signals[`signals${infoType}` as keyof typeof signals]?.length === 0 ||
        timestamp + 60 * 15 < Date.now()
      ) {
        getOtherSignals()
          .then((res: AxiosResponse) => {
            let signalsSorted = res.data.sort((a: Signal, b: Signal) => b.score - a.score);
            setSignals(infoType, signalsSorted);
            setLoadingSignals(false);
          })
          .catch(() => {
            setLoadingSignals(false);
          });
        setTimestamp(Date.now());
      }
      if (
        news[`news${infoType}` as keyof typeof news]?.length === 0 ||
        timestamp + 60 * 15 < Date.now()
      ) {
        getOtherNews({sort_by_ranking: true, sort_by_date: false, ranking_above: 0, limit:9})
          .then((res: AxiosResponse) => {
            let newsFormatted = res.data.map((news: SingleNews) => {
              return {...news, content: formatText(news.content)}
            } ); 
            setNews(infoType, newsFormatted);
            setLoadingNews(false);
          })
          .catch(() => {
            setLoadingNews(false);
          });
        setTimestamp(Date.now());
      }
    
  }, []);

  return (
    <>
         <SignalSection infoType={infoType} loadingNews={loadingNews} loadingSignals={loadingSignals}></SignalSection>
    </>
  );
};
export default SignalPeerContainer;