import { IoCopyOutline } from 'react-icons/io5';
import { IoIosSearch, IoIosExpand } from 'react-icons/io';
import { LiaComment } from 'react-icons/lia';
import ExpandModal from '@components/ExpandModal';
import { useState } from 'react';
import MarkdownViewer from '@components/MarkdownViewer';
import useCopyToClipboard from '@hooks/useCopyToClipboard';

interface SectionTextareaProps {
  title?: string;
  content: string;
  modalContent: string;
  hasIcons: boolean;
  viewerCallback?: () => void;
  assistantCallback?: () => void;
}

const SectionTextarea = ({
  title,
  content,
  modalContent,
  hasIcons,
  viewerCallback,
  assistantCallback
}: SectionTextareaProps): JSX.Element => {
  const { copyToClipboard } = useCopyToClipboard();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <div className="mt-4">
      <div className="flex">
        <div className="flex-auto border border-greyLdo rounded-lg mr-5 p-3 text-sm">
          {title && <div className="font-semibold text-lg mb-2">{title}</div>}
          <MarkdownViewer text={content} />
        </div>
        {hasIcons && (
          <div className="flex flex-col w-20 text-white">
            <div className="flex justify-between mb-2 ">
              <button
                onClick={() => copyToClipboard(content)}
                className="flex items-center justify-center bg-[#39414C] hover:bg-[#EB0029] shadow rounded-lg p-2 hover:scale-105 mr-2"
                title="Copy"
              >
                <IoCopyOutline size={20} />
              </button>
              <button
                className="flex items-center justify-center bg-[#39414C] hover:bg-[#EB0029] shadow rounded-lg p-2 hover:scale-105"
                onClick={() => viewerCallback && viewerCallback()}
                title="Approfondisci"
              >
                <IoIosSearch size={20} />
              </button>
            </div>
            <div className="flex justify-between">
              <button
                className="flex items-center justify-center bg-[#39414C] hover:bg-[#EB0029] shadow rounded-lg p-2 hover:scale-105 mr-2"
                onClick={() => setIsModalOpen(true)}
                title="Expand"
              >
                <IoIosExpand size={20} />
              </button>
              <button
                className="flex items-center justify-center bg-[#39414C] hover:bg-[#EB0029] shadow rounded-lg p-2 hover:scale-105"
                title="Go to the assistant"
                onClick={() => assistantCallback && assistantCallback()}
              >
                <LiaComment size={20} />
              </button>
            </div>
          </div>
        )}
      </div>
      {isModalOpen && (
        <ExpandModal onClose={() => setIsModalOpen(false)} title={title}>
          <div className="p-4 text-sm">{modalContent}</div>
        </ExpandModal>
      )}
    </div>
  );
};

export default SectionTextarea;
