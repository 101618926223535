import airbusLogo from '@assets/peer_logo/Airbus_logo.png';
import baeSystemsLogo from '@assets/peer_logo/BAE_Systems_logo.wine.png';
import boeingLogo from '@assets/peer_logo/Boeing_logo.png';
import dassaultLogo from '@assets/peer_logo/Dassault-Aviation_logo.png';
import fincantieriLogo from '@assets/peer_logo/fincantieri_square.png';
import generalDynamicsLogo from '@assets/peer_logo/General-Dynamics_logo.png';
import hensoldtLogo from '@assets/peer_logo/hensoldt_square.png';
import hiiLogo from '@assets/peer_logo/HII_logo.png';
import l3harrisLogo from '@assets/peer_logo/L3Harris_logo.png';
import leonardoLogo from '@assets/peer_logo/Leonardo_square.png';
import leonardoDRSLogo from '@assets/peer_logo/Leonardo_DRS_square.png';
import lockheedMartinLogo from '@assets/peer_logo/Martin_square.png';
import northropGrummanLogo from '@assets/peer_logo/Northrop_square.png';
import rheinmetallLogo from '@assets/peer_logo/Rheinmetall_logo.png';
import rollsRoyceLogo from '@assets/peer_logo/Rolls-royce_logo.png';
import rtxLogo from '@assets/peer_logo/RTX_square.png';
import saabLogo from '@assets/peer_logo/Saab_square.png';
import safranLogo from '@assets/peer_logo/Safran_square.png';
import textronLogo from '@assets/peer_logo/Textron_logo.png';
import thalesLogo from '@assets/peer_logo/Thales_logo.png';

const peerLogos: Record<string, string> = {
  'Airbus': airbusLogo,
  'BAE Systems': baeSystemsLogo,
  'Boeing': boeingLogo,
  'Dassault Aviation': dassaultLogo,
  'Fincantieri': fincantieriLogo,
  'General Dynamics': generalDynamicsLogo,
  'HENSOLDT': hensoldtLogo,
  'HII': hiiLogo,
  'L3Harris': l3harrisLogo,
  'Leonardo': leonardoLogo,
  'Leonardo DRS': leonardoDRSLogo,
  'Lockheed Martin': lockheedMartinLogo,
  'Northrop Grumman': northropGrummanLogo,
  'Rheinmetall': rheinmetallLogo,
  'Rolls-Royce': rollsRoyceLogo,
  'RTX Corporation': rtxLogo,
  'SAAB': saabLogo,
  'Safran': safranLogo,
  'Textron': textronLogo,
  'Thales': thalesLogo
};
export const getPeerLogo = (peerName: string): string | null => {
  return peerLogos[peerName] || null;
};
