import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FaTimes, FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa';

interface Element {
  name: string;  // obbligatorio
  id: number;    // obbligatorio
  [key: string]: any;
}

export interface SearchableDropdownSectionProps {
  dropdownDescription: string;
  elements: Element[];
  selectedElement: Element | null;
  handleSearch?: (searchTerm: string) => void;
  handleClickDropdown: (elementId: number) => void;
  handleCleanDropdown: () => void;
  isSearchble?: boolean;
}

const SearchableDropdownSection = (
  props: SearchableDropdownSectionProps,
): JSX.Element => {
  const {
    dropdownDescription,
    elements,
    selectedElement,
    handleSearch,
    handleClickDropdown,
    handleCleanDropdown,
    isSearchble,
  } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const onSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const newSearchTerm = (event.target as HTMLInputElement).value;
    setSearchTerm(newSearchTerm);
    if (handleSearch) {
      handleSearch(newSearchTerm);
    }
  };


  const onClickDropdown = (e: React.MouseEvent<HTMLLIElement>): void => {
    const element: number = e.currentTarget.dataset.value
      ? parseInt(e.currentTarget.dataset.value)
      : -1;
    handleClickDropdown(element);
    setIsOpen(false);
  };

  const clearFilter = (): void => {
    setSearchTerm('');
    handleCleanDropdown();
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="relative inline-block text-left" ref={dropdownRef}>
        <div>
          <button
            type="button"
            className="flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
            onClick={() => setIsOpen((prevState) => !prevState)}
          >
            {selectedElement ? (
              selectedElement.name
            ) : (
              <span>{dropdownDescription}</span>
            )}
            <div className="flex">
              {selectedElement && (
                <FaTimes
                  className="w-5 h-5 ml-2 cursor-pointer"
                  onClick={() => clearFilter()}
                />
              )}
              {isOpen ? (
                <FaChevronUp className="w-5 h-5 ml-2 -mr-1" />
              ) : (
                <FaChevronDown className="w-5 h-5 ml-2 -mr-1" />
              )}
            </div>
          </button>
        </div>

        {isOpen && (
          <div className="origin-top-right absolute right-0 w-56 rounded-b-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none w-full z-20">
            <div className="pt-2 pb-4 px-2">
              {isSearchble && (
                <div className="flex justify-center my-2 px-2 relative">
                  <input
                    type="text"
                    className={`w-full px-4 py-2 border border-gray-300 rounded-full h-8 focus:outline-none ${
                      !searchTerm ? 'pl-7' : ''
                    }`}
                    placeholder="Search"
                    value={searchTerm}
                    onChange={onSearch}
                  />
                  {!searchTerm && (
                    <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 w-4 h-4 text-[#9CA3BC]" />
                  )}
                </div>
              )}
              <ul className="max-h-60 overflow-y-scroll mt-4">
                {elements
                  // Sort the elements alphabetically by the 'name' property
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((option, index) => (
                    <li
                      key={index}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      data-value={option?.id}
                      onClick={onClickDropdown}
                    >
                      {option?.name}
                    </li>
                  ))}

              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchableDropdownSection;