import useNewsService from '@services/newsService/useNewsService';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Signal, SingleNews } from '@models/news';
import { Skeleton } from 'antd';
import CardsScheleton from '@components/CardsSkeleton/CardsSkeleton';
import NewsSingleCard from '@components/NewsSingleCard/NewsSingleCard';
import SignalImage from '@assets/leonardoSignal.jfif';
import { IoArrowBackCircle } from "react-icons/io5";
import {formatText} from '@utils/newsFormatter';



const SignalPage = (): JSX.Element => {
  let { signalId } = useParams();
  const { getPeerNews, getPeerSignals, getOtherNews, getOtherSignals } = useNewsService();
  const navigate = useNavigate();
  const [signal, setSignal] = useState<Signal | null>();
  const [news, setNews] = useState<SingleNews[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const {pathname} = useLocation();

  function hasNumbers(t: string) {
    var regex = /\d/g;
    return regex.test(t);
  }

  useEffect(() => {
    if (typeof signalId !== 'undefined' && hasNumbers(signalId)) {
      if(!pathname.includes('/signal/others')) {
        let getSig = getPeerSignals(parseInt(signalId as string, 10));
        let getNews = getPeerNews({cluster_id: parseInt(signalId as string, 10)});
        Promise.all([getSig, getNews])
          .then(([resSig, resNews]: AxiosResponse[]) => {
            setSignal(resSig.data);
            let newsFormatted = resNews.data.map((news: any) => {
              return {...news, content: formatText(news.content)}
            } ); 
            setNews(newsFormatted);
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });
      } else {
        let getSig = getOtherSignals(parseInt(signalId as string, 10));
        let getNews = getOtherNews({cluster_id: parseInt(signalId as string, 10)});
        Promise.all([getSig, getNews])
          .then(([resSig, resNews]: AxiosResponse[]) => {
            setSignal(resSig.data);
            let newsFormatted = resNews.data.map((news: SingleNews) => {
              return {...news, content: formatText(news.content)}
            } ); 
            setNews(newsFormatted);
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });
      }
     
    }
  }, []);

  return (
    <>
      <div className="flex flex-col items-center justify-center text-darkBlueLdo mb-8">
        <div className="flex flex-col  bg-[#4A535F] signals-container min-h-36 w-full ">
          <img
            src={SignalImage}
            className="w-full h-64 object-cover object-center"
          />
          <div className='py-10 flex flex-col items-center'>
            <span className="text-4xl font-bold  text-white">Signal</span>
            {loader && <Skeleton paragraph={{ rows: 6 }} />}
            {!loader && signal && (
              <>
                <div className="w-full p-10 flex flex-col items-center">
                  <h3 className="text-white text-2xl font-bold mb-6">
                    {signal?.title}
                  </h3>
                  <p className="text-white text-lg 2xl:px-32">{signal?.subtitle}</p>
                </div>
              </>
            )}{' '}
            {!loader && !signal && (
              <>
                <h6 className="text-white text-base italic flex ">
                  Signal not found
                </h6>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center text-darkBlueLdo my-8">
          <span className="text-3xl font-bold mb-6">Top News</span>
          {loader && <CardsScheleton loading={loader}></CardsScheleton>}

          {!loader && news?.length > 0 && (
            <>
              <div className="p-6">
                <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:px-36">
                  {' '}
                  {news.map((singleNews, index) => (
                    <NewsSingleCard
                      key={index}
                      singleNews={singleNews}
                      index={index}
                    ></NewsSingleCard>
                  ))}
                </div>
              </div>
            </>
          )}
          {!loader && news?.length === 0 && (
            <h6 className="text-darkBlueLdo text-base italic flex justify-center items-center">
              News not found
            </h6>
          )}
        </div>
      </div>
      <IoArrowBackCircle onClick={() => navigate(-1)} className='text-4xl text-white cursor-pointer absolute top-[375px] left-2 z-10' />
    </>
  );
};

export default SignalPage;
