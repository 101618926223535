import React from 'react';
import { FaTimes, FaExclamationCircle } from 'react-icons/fa';
import { useToastStore } from '@services/toast/useToastStore';
import './styles.css';

const Toast: React.FC = () => {
  const { message, isVisible, hideToast } = useToastStore();

  if (!isVisible) return;

  return (
    <div className="toast-container">
      <div className="toast-message">
        <FaExclamationCircle className="icon text-red-500" />
        <span className="flex-1">{message}</span>
        <button onClick={hideToast}>
          <FaTimes className="close-icon" />
        </button>
      </div>
      <div className="toast-progress">
        <div className="toast-progress-bar"></div>
      </div>
    </div>
  );
};

export default Toast;
