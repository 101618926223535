import { InfoTypes, Signal, SingleNews } from '@models/news';
import useSignalsStore from '@stores/signalsStore/useSignalsStore';
import { Image, Divider, Skeleton } from 'antd';
import Signals from '@assets/signals.jfif';
import './styles.css';
import { Link } from 'react-router-dom';
import CardsScheleton from '@components/CardsSkeleton/CardsSkeleton';
import NewsSingleCard from '@components/NewsSingleCard/NewsSingleCard';

interface SignalSectionProps {
  infoType: InfoTypes;
  loadingNews: boolean;
  loadingSignals: boolean;
}

const SignalSection: React.FC<SignalSectionProps> = ({
  infoType,
  loadingNews,
  loadingSignals,
}): JSX.Element => {
  const { signals, news, timestamp } = useSignalsStore((state) => state);

  function SignalContainer(props: { signalToRender: Signal }): JSX.Element {
    const { signalToRender: signal } = props;
    return (
      <>
        <div className="p-6">
          <h5 className="text-white text-lg uppercase font-bold">
            {signal.title}
          </h5>
          <p className="text-white text-base">{signal.subtitle}</p>
          {/* <span className="text-white text-xs">{signal.date}</span> */}
        </div>
        <Divider />
      </>
    );
  }

  return (
    <>
      <div className="grid grid-cols-5 bg-[#4A535F] signals-container">
        <div className="full-img sticky ">
          <Image src={Signals} preview={false}></Image>
        </div>
        <div className="col-span-4 text-white ">
          <div className="w-full flex justify-between overflow-y-auto">
            <h1 className="text-l font-bold bg-redLdo p-1">Signals</h1>
            <span className="pr-3 text-xs flex items-center">
              <b className="pr-1">Last Update:</b>
              {new Date(timestamp).toLocaleTimeString('it-IT')}
            </span>
          </div>
          {signals[`signals${infoType}` as keyof typeof signals]?.map(
            (signal: Signal, index) => {
              return (
                <Link to={(infoType === 'Peer')? `/signal/${signal.id}` : `/signal/others/${signal.id}`} key={index + '-signalLink'}>
                  <SignalContainer
                    signalToRender={signal}
                  />
                </Link>
              );
            },
          )}
          {signals[`signals${infoType}` as keyof typeof signals]?.length ===
            0 &&
            !loadingSignals && (
              <h6 className="text-white text-base italic flex justify-center items-center">
                Signals not found
              </h6>
            )}
          {loadingSignals && (
            <>
              <div className="pt-6 px-6">
                <Skeleton paragraph={{ rows: 6 }} />
                <Divider></Divider>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center text-darkBlueLdo my-8">
        <span className="text-3xl font-bold mb-6">Top News</span>
      </div>
      {news[`news${infoType}` as keyof typeof news]?.length > 0 && (
        <>
          <div className="p-6">
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:px-36">
              {news[`news${infoType}` as keyof typeof news].map(
                (singleNews: SingleNews, index) => {
                  return (
                    <NewsSingleCard
                      key={index}
                      singleNews={singleNews}
                      index={index}
                    ></NewsSingleCard>
                  );
                },
              )}
            </div>
          </div>
        </>
      )}
      {news[`news${infoType}` as keyof typeof news]?.length === 0 &&
        !loadingNews && (
          <h6 className="text-darkBlueLdo text-base italic flex justify-center items-center">
            News not found
          </h6>
        )}
      {loadingNews && <CardsScheleton loading={loadingNews} />}
    </>
  );
};
export default SignalSection;
