import { create } from 'zustand';

interface popupInfoState {
  text: string;
  isVisible: boolean;
  showPopup: (text: string) => void;
}

export const usePopupInfo = create<popupInfoState>((set) => ({
  text: '',
  isVisible: false,
  showPopup: (text: string): void => {
    set({ text, isVisible: true });
    setTimeout(() => set({ isVisible: false }), 3000); // Hide after 3 seconds
  },
}));
