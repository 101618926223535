import { Topic } from '@stores/filters/useTopicsFilter.ts';
import { create } from 'zustand';

export interface TopicsStore {
  topics: Topic[];
  setTopics: (topics: Topic[]) => void;
}

const useTopicsStore = create<TopicsStore>((set) => ({
  topics: [],
  setTopics: (topics):void => set({ topics }),
}));


export default useTopicsStore;