import { create } from 'zustand';
import axios from 'axios';

export interface ReportInterface {
  peer: string;
  topic_reports: TopicReportInterface[];
  document: DocumentInterface[];
}

export interface TopicReportInterface {
  topic_title: string;
  report: string;
  highlights: string;
  relevant_chunks: number[];
}

export interface DocumentInterface {
  page_id: number;
  chunks: ChunkInterface[];
}

export interface ChunkInterface {
  chunk_id: number;
  page_id: number;
  text: string;
  bbox: number[];
}

export interface ReportOutput {
  topic_title: string;
  report: string;
  highlights: string;
  //reportMarkdown: string;
  relevant_chunks: (ChunkInterface)[];
}

export interface ReportOutputState {
  reportOutput: ReportOutput;
  setCurrentReport: (peer: string, topic: string) => void;
}

// build a function receiving a peer and a topic, load the ReportInterface from the assets under mock-apis filtering by peer, corrensponding to the file name
// then build a TopicReportInterfaceOutput filtering by topic title and then return it

const getReport = async (
  peer: string,
  topic: string,
): Promise<ReportOutput | null> => {
  // load the report from the mock-apis folder
  const report: ReportInterface = await loadReport(peer);
  //const reportMarkdown: string = await loadReportMarkdown(peer);

  // find the first TopicReportInterface from the topic_reports array
  const topicReport: TopicReportInterface | undefined =
    report.topic_reports?.find(
      (t: TopicReportInterface) =>
        t.topic_title.toLowerCase() === topic.toLowerCase(),
    );

  if (!topicReport) {
    return null;
  }

  const mappedRelevantChunks: ChunkInterface[] = [];
  if(topicReport.relevant_chunks) {
    topicReport.relevant_chunks.forEach((chunkId: number) => {
      const doc = report.document.find((d: DocumentInterface) =>
        d.chunks.find((c: ChunkInterface) => c.chunk_id === chunkId),
      )
    
    if(doc) {
      const chunk = doc.chunks.find((c: ChunkInterface) => c.chunk_id === chunkId);

      if(chunk){
      mappedRelevantChunks.push(chunk);
      }
    }
        
    });
  }


  const topicOutput: ReportOutput = {
    topic_title: topicReport.topic_title,
    report: topicReport.report,
    highlights: topicReport.highlights,
    relevant_chunks: mappedRelevantChunks,
    //reportMarkdown: reportMarkdown
  };

  return topicOutput;
};

const loadReport = async (peer: string): Promise<ReportInterface> => {
  // load from the mock-apis folder without using require
  //  backtick please
  const response = await axios.get(`/mock-apis/json/${peer}_output.json`);

  return response.data;
};

// const loadReportMarkdown = async (peer: string): Promise<string> => {
//     // load from the mock-apis folder without using require
//     //  backtick please
//     const response = await axios.get(`/mock-apis/markdown/${peer}_report.md`);

//     return response.data;

// }

export const useReportsSearch = create<ReportOutputState>(
  (set): ReportOutputState => ({
    reportOutput: {
      topic_title: '',
      report: '',
      highlights: '',
      relevant_chunks: [],
      //reportMarkdown: ''
    },
    setCurrentReport: async (peer: string, topic: string): Promise<void> => {
      const report = await getReport(peer, topic);
      if (!report) return;
      set({ reportOutput: report });
    },
  }),
);
