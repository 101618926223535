import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import { ModelState } from '@stores/modelStore/useModelsStore';

export interface ChatMessage {
  role: 'human' | 'ai';
  message: string;
  llm_model: ModelState;
}

export interface StreamMessage {
  id: number;
  type: 'chunk' | 'log';
  content: string;
}

export interface chatHistoryState {
  chatHistory: ChatMessage[];
  streamChatMessages: StreamMessage[];
  addStreamChatMessages: (streamMessage: StreamMessage) => void;
  clearStreamChatMessages: () => void;
  threadId: string;
  setThreadId: (threadId: string) => void;
  setNewThreadId: () => void;
  cancelTokenRemoteChainV3: AbortController;
  setCancelTokenRemoteChainV3: () => void;
  updateLastChatMessageText: (text: string) => void;
  setChatHistory: (chatMessage: ChatMessage) => void;
  clearChatHistory: () => void;
  removePlaceholderMessage: () => void;
  replaceChatHistory: (chatHistory: ChatMessage[]) => void;
}

export const useChatHistory = create<chatHistoryState>(
  (set): chatHistoryState => ({
    chatHistory: [] as ChatMessage[],
    threadId: '',
    streamChatMessages: [] as StreamMessage[],
    addStreamChatMessages: (streamMessage: StreamMessage[] | StreamMessage) => {
      if (streamMessage instanceof Array) {
        set((state: chatHistoryState) => ({
          streamChatMessages: [...state.streamChatMessages, ...streamMessage],
        }));
      } else {
        set((state: chatHistoryState) => ({
          streamChatMessages: [...state.streamChatMessages, streamMessage],
        }));
      }
    },
    clearStreamChatMessages: () => set({ streamChatMessages: [] }),
    setThreadId: (threadId: string): void => {
      if (threadId == '-' || threadId == '') {
        set({ threadId: uuidv4() });
      } else {
        set({ threadId });
      }
    },
    setNewThreadId: () => set({ threadId: uuidv4() }),
    cancelTokenRemoteChainV3: new AbortController(),
    setCancelTokenRemoteChainV3: () => set({ cancelTokenRemoteChainV3: new AbortController() }),
    updateLastChatMessageText: (text: string) => {
      set((state: chatHistoryState) => {
        let newMessage: string
        if (state.chatHistory[state.chatHistory.length - 1].message === '...') {
          newMessage = state.chatHistory[state.chatHistory.length - 1].message = text;
        } else {
          newMessage = state.chatHistory[state.chatHistory.length - 1].message + text
        }
        let updateLastChatMessage: ChatMessage = {
          role: state.chatHistory[state.chatHistory.length - 1].role,
          llm_model: state.chatHistory[state.chatHistory.length - 1].llm_model,
          message: newMessage
        }
        return {
          chatHistory: [
            ...state.chatHistory.slice(0, -1),
            updateLastChatMessage,
          ],
        };
      });
    },
    setChatHistory: (chatMessage: ChatMessage) =>
      set((state: chatHistoryState) => ({
        chatHistory: [...state.chatHistory, chatMessage],
      })),
    removePlaceholderMessage: () =>
      set((state: chatHistoryState) => ({
        chatHistory: state.chatHistory.slice(0, -1) ?? [],
      })),
    clearChatHistory: () => set({ chatHistory: [] }),
    replaceChatHistory: (chatHistory: ChatMessage[]) => set({ chatHistory }),
  }),
);

export default useChatHistory;
