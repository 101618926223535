import React, { useState, useRef, useEffect } from 'react';

import { RxHamburgerMenu } from 'react-icons/rx';
import { MdAssistant, MdExitToApp } from 'react-icons/md';
import useAuthStore from '@stores/authStore/useAuthStore';
import { NavLink, useNavigate } from 'react-router-dom';
import { TbReport } from 'react-icons/tb';
import { FaNewspaper } from 'react-icons/fa';

const MobileMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const clearToken = useAuthStore((state) => state.clearToken);

  const handleLogout = (): void => {
    setIsOpen(false);
    clearToken();
    navigate('/login');
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        type="button"
        className="flex items-center justify-center w-8 h-8 rounded-full ml-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <RxHamburgerMenu
          className={`w-8 h-8 hover:text-red-500 ${
            isOpen ? 'text-red-500' : 'text-white'
          }`}
        />
      </button>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-white z-50">
          <div className="py-1">
            <NavLink
              to="/report"
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:text-red-500 group hover:bg-slate-50"
            >
              <TbReport className="mr-3 text-gray-700 group-hover:text-red-500" />
              Report
            </NavLink>
          </div>{' '}
          <div className="py-1">
            <NavLink
              to="/assistente"
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:text-red-500 group hover:bg-slate-50"
            >
              <MdAssistant className="mr-3 text-gray-700 group-hover:text-red-500" />
              Assistant
            </NavLink>
          </div>{' '}
          <div className="py-1">
            <NavLink
              to="/signals"
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:text-red-500 group hover:bg-slate-50"
            >
              <FaNewspaper className="mr-3 text-gray-700 group-hover:text-red-500" />
              Signals
            </NavLink>

        
          </div>
          <div className="py-1">
              <button
                onClick={handleLogout}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:text-red-500 group hover:bg-slate-50"
              >
                <MdExitToApp className="mr-3 text-gray-700 group-hover:text-red-500" />
                Exit
              </button>
            </div>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
