import React from 'react';
import SectionTextarea from '@components/SectionTextarea';
import { Transcript } from '@models/synthReport';
import MetaDataTags from '@components/MetaDataTags'; // Usa la nuova interfaccia Transcript

interface QuestionsProps {
  title: string;
  hasButton: boolean;
  results: number;
  questions: Transcript;
  quarter: string;
  files: string[];
}

const Questions: React.FC<QuestionsProps> = ({ title, hasButton, results, questions, quarter, files }): JSX.Element => {
  const categoryComponents = {
    questioner: (text: string): JSX.Element => (
      <span className="text-sm font-bold italic">
        <span className="text-red-500 mr-1">Q:</span> {text}
      </span>
    ),
    answerer: (text: string): JSX.Element => (
      <span className="text-sm font-bold italic">
        <span className="text-green-500 mr-1">A:</span> {text}
      </span>
    ),
    conversation: (text: string): JSX.Element => (
      <SectionTextarea content={text} modalContent={text} hasIcons={false} />
    ),
  };

  return (
    <div className="mt-10">
      <div className="flex items-center">
        <span className="mr-1 whitespace-nowrap text-red-500 font-bold text-xl">{title}</span>
        <div className="bg-greyLdo h-[1px] flex-grow"></div>
      </div>
      {/* MetaDataTags per Questions */}
      <MetaDataTags quarter={quarter} files={files}/>
      <div className="text-sm text-slate-600 my-3">({results} risultati)</div>
      {/* Sezione Summary */}
      {questions.summary && (
        <div>
          <span className="font-bold">Summary :</span>
          <SectionTextarea content={questions.summary} modalContent={questions.summary} hasIcons={false} />
        </div>
      )}

      {/* Pulsante per highlights */}
      {hasButton && (
        <div className="flex justify-center items-center">
          <button className="mt-10 border-2 border-darkBlueLdo rounded-lg p-2 font-semibold px-10 hover:bg-darkBlueLdo hover:text-white text-darkBlueLdo">
            Visualizza altri highlights
          </button>
        </div>
      )}

      {/* Mappatura degli elementi pertinenti */}
      {questions.relevant_elements?.map((element, index) => (
        <div key={index} className="mt-4 mb-4">
          {categoryComponents[element.category as keyof typeof categoryComponents]?.(element.text)}
        </div>
      ))}
    </div>
  );
};

export default Questions;
