import { create } from 'zustand';
import { persist} from 'zustand/middleware';

export interface Topic {
  id: number;
  name: string;
}

export interface TopicState {
  topic: Topic | null;
  setTopic: (topicToChange: Topic | null) => void;
  clearTopic: () => void;
}

export const useTopicsFilter = create<TopicState>()(
  persist(
    (set) => ({
      topic: null,
      setTopic: (topicToChange: Topic | null): void => set({ topic: topicToChange }),
      clearTopic: (): void => set({ topic: null }),
    }),
    {
      name: 'topic', // Nome della chiave per il localStorage
      storage: {
        getItem: (name) => {
          const item = localStorage.getItem(name);
          return item ? JSON.parse(item) : null;
        },
        setItem: (name, value) => {
          localStorage.setItem(name, JSON.stringify(value));
        },
        removeItem: (name) => {
          localStorage.removeItem(name);
        },
      },
    }
  )
);
