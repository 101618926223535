import { create } from 'zustand';

export interface AuthStore {
  token: string | null;
  setToken: (token: string) => void;
  clearToken: () => void;
}

const useAuthStore = create<AuthStore>((set) => ({
  token: localStorage.getItem('tokenLdo') || null,
  setToken: (token: string): void => {
    localStorage.setItem('tokenLdo', token);
    set({ token });
  },
  clearToken: (): void => {
    localStorage.removeItem('tokenLdo');
    set({ token: null });
  },
}));

export default useAuthStore;
