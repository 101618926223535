import axios from 'axios';
import useAuthStore from '@stores/authStore/useAuthStore';
import { AxiosInstance } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

// Use the base URL from the environment variable with Vite
const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

const useAxios = (): AxiosInstance => {
  const token = useAuthStore((state) => state.token);
  const clearToken = useAuthStore((state) => state.clearToken);
  const navigate = useNavigate();

  useEffect(() => {
    const requestInterceptor = api.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    const responseInterceptor = api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate('/login');
        }
        return Promise.reject(error);
      },
    );

    return (): void => {
      api.interceptors.request.eject(requestInterceptor);
      api.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return api;
};

export default useAxios;
