import { LiaComment } from 'react-icons/lia';
import { IoCopyOutline } from 'react-icons/io5';
import useCopyToClipboard from '@hooks/useCopyToClipboard';

interface PdfSectionTextareaProps {
  title?: string;
  content: string;
}

const PdfSectionTextarea = ({ title, content }: PdfSectionTextareaProps): JSX.Element => {

  const { copyToClipboard } = useCopyToClipboard();

  return (
    <>
      <div className="mt-4 mb-4 border border-gray-300 rounded-lg p-6 flex flex-col h-full">
        <div className="flex-auto mb-4 overflow-y-auto">
          {title && <div className="font-semibold text-2xl mb-4">{title}</div>}
          <div className="text-sm">
            {content.split('\n').map((paragraph, index) => (
              <p key={index} className="mb-2">{paragraph}</p>
            ))}
          </div>
        </div>

      </div>
      <div className="flex justify-end space-x-2">
        <button
          onClick={() => copyToClipboard(content)}
          className="flex items-center justify-center border-2 border-gray-300 rounded-lg p-2 hover:scale-105">
          <IoCopyOutline size={25} />
        </button>
        <button
          className="flex items-center justify-center border-2 border-gray-300 rounded-lg p-2 hover:scale-105">
          <LiaComment size={25} />
        </button>
      </div>
    </>
  );
};

export default PdfSectionTextarea;
