import { GoThumbsup, GoThumbsdown } from 'react-icons/go';
import { getPeerLogo } from '@utils/getPeerLogo';
import { Tooltip } from 'antd';

interface CompanyNewsCardProps {
  company: string;
  description: string;
  category: string;
  thumbsUp: boolean;
}

const CompanyNewsCard = ({ company, description, category, thumbsUp }: CompanyNewsCardProps): JSX.Element => {
  return (
    <div className="flex flex-col justify-between border rounded-lg shadow-lg p-4 bg-white mb-4 w-80 min-h-[400px]"> {/* Minima altezza aumentata */}
      {/* Logo aziendale */}
      <div className="flex justify-center mb-4">
        <img src={getPeerLogo(company)!} alt={company} className="w-40 h-24 object-contain" />
      </div>

      {/* Descrizione */}
      <div className="bg-gray-100 p-4 rounded-lg flex-grow text-center">
        <p className="text-md">
          <span className="font-bold">{company}</span> {description}
        </p>
      </div>

      {/* Categoria e pollice */}
      <div className="flex items-center justify-between mt-4">
        <Tooltip color="black" placement="top">
          <span className="text-sm font-semibold text-white bg-[#4A535F] p-2 rounded-lg">
            {category}
          </span>
        </Tooltip>
        {thumbsUp ? (
          <GoThumbsup size={40} className="text-green-500" />
        ) : (
          <GoThumbsdown size={40} className="text-red-500" />
        )}
      </div>
    </div>
  );
};

export default CompanyNewsCard;
