import useAxios from '@hooks/api/useAxios';
import { AxiosResponse } from 'axios';

type PdfService = {
  getPdf: (
    peerId: number,
    year: number,
    quarter: number,
    fileName: string,
    type?: string,
  ) => Promise<AxiosResponse>;
};

const usePdfService = (): PdfService => {
  const api = useAxios();

  const getPdf = (
    peerId: number,
    year: number,
    quarter: number,
    fileName: string,
    type?: string,
  ): Promise<AxiosResponse> =>
    api.get(`api/resource/${year}/${quarter}/${peerId}/${type}/${fileName}/`, {
      responseType: 'arraybuffer',
      headers: {
          'Content-Type': 'application/pdf',
      }
    });

  return {
    getPdf,
  };
};

export default usePdfService;
