import React, { useState, useRef, useEffect } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { MdDelete, MdEdit } from 'react-icons/md';
import { BsThreeDots } from 'react-icons/bs';
import { FaRegSave } from 'react-icons/fa';
import { MdOutlineCancel } from 'react-icons/md';
import useChatService from '@services/chatService/useChatService';
import useChatHistory, { ChatMessage } from '@stores/chatHistoryStore/chatHistory';
import { useChatArchive } from '@services/chatArchive/useChatArchive';
import useChangeLayout from '@services/assistenteLayout';
import { useChatArchiveFiltered } from '@services/chatArchiveFiltered/useChatArchiveFiltered';
import { useToastStore } from '@services/toast';
import useModelStore, { ModelState } from '@stores/modelStore/useModelsStore';

interface PreviousChatProps {
  id: number;
  title: string;
  chatThreadId: string;
  chatModel?: ModelState;
}

const PreviousChat = ({
                        id,
                        title,
                        chatThreadId,
                        chatModel,
                      }: PreviousChatProps): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [isSelected, setIsSelected] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { deleteChat } = useChatArchive();
  const { deleteFilteredChat, updateChatFilteredTitle } =
    useChatArchiveFiltered();
  const setLayout = useChangeLayout((state) => state.setLayout);
  const { getAllChatMessages, deleteChatById, updateChatById } =
    useChatService();
  const {
    replaceChatHistory,
    clearChatHistory,
    setThreadId,
    setNewThreadId,
    cancelTokenRemoteChainV3,
    setCancelTokenRemoteChainV3,
    threadId,
  } = useChatHistory();
  const model = useModelStore((state) => state.model);
  const { setChatId, updateChatTitle, chatId } = useChatArchive();
  const showToast = useToastStore((state) => state.showToast);

  const onDelete = async (): Promise<void> => {
    try {
      await deleteChatById(id);
      deleteChat(id);
      deleteFilteredChat(id);
      if (id === chatId) {
        setLayout('default');
        cancelTokenRemoteChainV3.abort();
        setCancelTokenRemoteChainV3();
        clearChatHistory();
        setNewThreadId();
      }
    } catch (error) {
      showToast('Error: Error during the deletion of the chat');
      console.log('error deleteChatById', error);
    }
  };

  // Move onMenuClick above items
  const onMenuClick: MenuProps['onClick'] = (e): void => {
    const { key } = e;
    if (key === '1') {
      setIsEditing(true);
    } else if (key === '2') {
      onDelete();
    }
  };

  const items: MenuProps['items'] = [
    {
      label: 'Edit',
      key: '1',
      icon: <MdEdit className="mr-1" />,
    },
    {
      type: 'divider' as const,
    },
    {
      label: 'Delete',
      key: '2',
      icon: <MdDelete className="mr-1" />,
      danger: true,
    },
  ];

  const handleSave = async (): Promise<void> => {
    if (newTitle === title) return;
    try {
      await updateChatById(id, {
        title: newTitle,
        thread_id: chatThreadId,
        llm_model: model,
      });
      updateChatTitle(id, newTitle);
      updateChatFilteredTitle(id, newTitle);
      setIsEditing(false);
    } catch (error) {
      showToast('Error: Error during the edit of the chat');
      console.log('error updateChatById', error);
    }
  };

  const handleClick: React.MouseEventHandler<HTMLDivElement> = async (): Promise<void> => {
    try {
      const response = await getAllChatMessages(id);
      setIsSelected(true);
      setThreadId(chatThreadId);
      setLayout('chat');
      const history = response.data.map((element: ChatMessage) => {
        if (!element?.llm_model && chatModel) {
          element.llm_model = chatModel;
        }
        return element;
      });
      replaceChatHistory([...history]);
      setChatId(id);
    } catch (error) {
      showToast('Error: Error on the retrieval of the chat messages');
      console.log('error getAllChatMessages', error);
    }
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    setIsSelected(threadId === chatThreadId);
  }, []);

  return (
    <div
      className={`flex items-start justify-between cursor-pointer px-4 py-2 border-b border-gray-300 transition-colors duration-200 ${
        isSelected
          ? 'no-draggable group relative rounded-lg active:opacity-90 bg-greyDarkLdo'
          : 'bg-white hover:bg-red-100'
      } `}
    >
      <div className="flex-grow" onClick={handleClick}>
        {isEditing ? (
          <div className="mr-2">
            <input
              type="text"
              value={newTitle}
              ref={inputRef}
              onChange={(e) => setNewTitle(e.target.value)}
              className="border rounded p-1 w-full"
            />
            <div className="flex items-center space-x-2 mt-2">
              <button
                onClick={handleSave}
                className="flex items-center justify-center bg-darkBlueLdo text-white p-2 rounded transition duration-300 ease-in-out"
              >
                <FaRegSave size={16} />
                <span className="ml-1 text-sm">Save</span>
              </button>
              <button
                onClick={() => setIsEditing(false)}
                className="flex items-center justify-center bg-red-500 text-white p-2 rounded hover:bg-red-600 transition duration-300 ease-in-out"
              >
                <MdOutlineCancel size={17} />
                <span className="ml-1 text-sm">Cancel</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="line-clamp-2">{title}</div>
        )}
      </div>
      {!isEditing && (
        <div
          className="p-1 mx-2 rounded-lg hover:cursor-pointer"
          onClick={(e) => e.stopPropagation()}
        >
          <Dropdown
            className="hover:cursor-pointer ml-3 w-1/6"
            menu={{
              items,
              onClick: onMenuClick,
            }}
            trigger={['click']}
          >
            <button className="ml-1">
              <BsThreeDots size={22} className="text-black" />
            </button>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default PreviousChat;
