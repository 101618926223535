import useAxios from '@hooks/api/useAxios';
import { AxiosResponse } from 'axios';

type PeerService = {
  getAllPeers: () => Promise<AxiosResponse>;
  initPeer: () => Promise<AxiosResponse>;
  getPeerById: (id: number) => Promise<AxiosResponse>;
  deletePeerById: (id: number) => Promise<AxiosResponse>;
  searchPeers: (searchTerm: string) => Promise<AxiosResponse>;
};

const usePeerService = (): PeerService => {
  const api = useAxios();

  const getAllPeers = (): Promise<AxiosResponse> => api.get('/api/peers/');
  const initPeer = (): Promise<AxiosResponse> => api.post('/api/peers/init');
  const getPeerById = (id: number): Promise<AxiosResponse> => api.get(`/api/peers/${id}`);
  const deletePeerById = (id: number): Promise<AxiosResponse> => api.delete(`/api/peers/${id}`);
  const searchPeers = (searchTerm: string): Promise<AxiosResponse> => api.get(`/api/peers/search?search_term=${searchTerm}`);

  return {
    getAllPeers,
    initPeer,
    getPeerById,
    deletePeerById,
    searchPeers,
  };
};

export default usePeerService;