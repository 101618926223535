import Report from '@pages/Report';
import Assistente from '@pages/Assistente';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from '@common/Navbar';
import Toast from '@common/Toast';
import Login from '@pages/Login';
import PdfViewer from '@pages/PdfViewer';
import useAuthStore from '@stores/authStore/useAuthStore';
import { ReactNode } from 'react';
import PopupInfo from '@common/PopupInfo';
import Signals from '@pages/Signals/signalsPage';
import SignalPage from '@pages/Signal/signalPage';

const PrivateRoute = ({ children }: { children: ReactNode }): JSX.Element => {
  const { token } = useAuthStore();
  return token ? <>{children}</> : <Navigate to="/login" />;
};

function App(): JSX.Element {
  const { pathname } = useLocation();

  return (
    <>
      {!(pathname === '/login') && <Navbar />}
      <Routes>
        <Route
          path="report"
          element={
            <PrivateRoute>
              <Report />
            </PrivateRoute>
          }
        />
        <Route
          path="assistente"
          element={
            <PrivateRoute>
              <Assistente />
            </PrivateRoute>
          }
        />  
        <Route
          path="signals/*"
          element={
            <PrivateRoute>
              <Signals />
            </PrivateRoute>
          }
        />   
            <Route
          path="signal/others/:signalId"
          element={
            <PrivateRoute>
              <SignalPage />
            </PrivateRoute>
          }
        />
        <Route
          path="signal/:signalId"
          element={
            <PrivateRoute>
              <SignalPage />
            </PrivateRoute>
          }
        />
        <Route
          path="pdf-viewer"
          element={
            <PrivateRoute>
              <PdfViewer />
            </PrivateRoute>
          }
        />
        <Route path="login" element={<Login />} />
        <Route path="*" element={<Navigate to="report" />} />
      </Routes>
      <Toast />
      <PopupInfo />
    </>
  );
}

export default App;
