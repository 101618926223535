import { GoSidebarCollapse } from 'react-icons/go';
import { RiChatNewLine } from 'react-icons/ri';

interface CollapsedChatSidebarProps {
  setIsCollapsed: (isCollapsed: boolean) => void;
}

const CollapsedChatSidebar = ({
  setIsCollapsed,
}: CollapsedChatSidebarProps): JSX.Element => {
  return (
    <div className=" p-2 flex justify-end">
      <button
        className="border-2 border-darkBlueLdo bg-[#39414C] rounded-lg p-2 mr-2  hover:bg-[#EB0029] text-white"
        onClick={() => setIsCollapsed(false)}
      >
        <GoSidebarCollapse size={22} />
      </button>
      <button className="border-2 border-darkBlueLdo bg-[#39414C] rounded-lg p-2 mr-2  hover:bg-[#EB0029] text-white">
        <RiChatNewLine size={22} />
      </button>
    </div>
  );
};

export default CollapsedChatSidebar;
